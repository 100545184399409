import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ArmyBuilderConfig, LoginSelector, Product } from 'army-builder-shared';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { SubscriptionService } from './subscription.service';

@Component({
    selector: 'wl-subscribe-button-web',
    template: `
        <ng-container
            *ngLet="{
                checkoutStatus: checkoutStatus$ | async,
                hasAccess: userHasAccess$ | async,
                hasExpired: hasExpired$ | async,
                loginState: login$ | async,
                freeTrial: freeTrial$ | async,
                userRoles: userRoles$ | async
            } as props"
        >
            <ng-container *ngIf="!props.userRoles.includes(product.associatedRole) || props.freeTrial">
                <form action="{{ config.apiBaseUrl }}/purchase/createCheckoutSession" method="POST">
                    <input type="hidden" name="priceId" value="{{ stripeProductKey }}" />
                    <input type="hidden" name="accessToken" value="{{ props.loginState?.user?.accessToken }}" />
                    <ion-button type="submit"> {{ 'GLOBAL.MENU_ITEMS.SUBSCRIBE' | translate }}</ion-button>
                </form>
            </ng-container>
        </ng-container>
    `,
    styles: [
        `
            form {
                display: flex;
                width: 100%;
            }

            ion-button {
                flex: 1 1 auto;
            }

            ion-card {
                margin-inline: 2px;
            }

            .buttons {
                padding: 0;
            }
        `
    ]
})
export class StripeSubscribeButtonComponent implements OnInit {
    freeTrial$ = this.subscriptionService.freeTrial$;
    userRoles$ = this.subscriptionService.userRoles$;
    userHasAccess$ = this.subscriptionService.userHasAccess$;
    hasExpired$ = this.subscriptionService.hasExpired$;
    stripeProductKey: string;
    checkoutStatus$ = this.route.queryParams.pipe(map((p) => p.checkoutStatus?.toLowerCase()));

    login$ = this.store.pipe(select(LoginSelector));

    @Input({ required: true })
    product: Product;

    constructor(
        public config: ArmyBuilderConfig,
        private route: ActivatedRoute,
        private platform: Platform,
        private subscriptionService: SubscriptionService,
        private store: Store
    ) {}

    ngOnInit() {
        this.stripeProductKey = this.config.environment === 'staging' ? this.product.stripeKeyStaging : this.product.stripeKeyProd;
    }
}
