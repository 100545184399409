import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { FORCE_ACTIONS } from './actions';
import {
    loadAllForcesReducer,
    addForceReducer,
    deleteForceReducer,
    updateForceReducer,
    upsertForceReducer,
    addPointsAdjustmentReducer,
    removePointsAdjustmentReducer,
    deletePlatoonReducer,
    duplicatePlatoonReducer,
    addPlatoonReducer
} from './reducers';
import { ForceState, initialForceState } from './state';
import { addUnitOptionReducer } from './reducers/addUnitOptionReducer';
import { removeUnitOptionReducer } from './reducers/removeUnitOptionReducer';

export type EntityChangeState = 'Clean' | 'Dirty' | 'Error' | 'Deleted';

export const forceReducer = createReducer(
    initialForceState,
    on(FORCE_ACTIONS.LOAD_ALL_FORCES, loadAllForcesReducer),
    on(FORCE_ACTIONS.ADD_FORCE, addForceReducer),
    on(FORCE_ACTIONS.DELETE_FORCE, deleteForceReducer),
    on(FORCE_ACTIONS.UPDATE_FORCE, updateForceReducer),
    on(FORCE_ACTIONS.UPSERT_FORCE, upsertForceReducer),
    on(FORCE_ACTIONS.ADD_POINTS_ADJUSTMENT, addPointsAdjustmentReducer),
    on(FORCE_ACTIONS.REMOVE_POINTS_ADJUSTMENT, removePointsAdjustmentReducer),
    on(FORCE_ACTIONS.DELETE_PLATOON, deletePlatoonReducer),
    on(FORCE_ACTIONS.DUPLICATE_PLATOON, duplicatePlatoonReducer),
    on(FORCE_ACTIONS.ADD_PLATOON, addPlatoonReducer),
    on(FORCE_ACTIONS.ADD_UNIT_OPTION, addUnitOptionReducer),
    on(FORCE_ACTIONS.REMOVE_UNIT_OPTION, removeUnitOptionReducer)
);

export interface GameSpecificForceReducer {
    gameId: string;
    reducer: ActionReducer<ForceState, Action>;
}
export const CUSTOM_FORCE_REDUCERS: { [gameId: string]: GameSpecificForceReducer } = {};
export const registerForceReducer = (gameSpecificReducer: GameSpecificForceReducer) => {
    console.log('Registering force reducer for ' + gameSpecificReducer.gameId);
    CUSTOM_FORCE_REDUCERS[gameSpecificReducer.gameId] = gameSpecificReducer;
};

export const getCombinedForceReducers = () => {
    return (state, action: Action) => {
        let newState = forceReducer(state, action);
        for (const r of Object.values(CUSTOM_FORCE_REDUCERS)) {
            newState = r.reducer(newState, action);
        }
        return newState;
    };
};
