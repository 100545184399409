import { DATA_ACTIONS } from './actions';

export function resetAppState(reducer) {
    return function (state, action) {
        if (action.type === DATA_ACTIONS.resetAppState) {
            state = {
                translation: state.translation
            };
        }
        return reducer(state, action);
    };
}
