import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    SettingsModule as SharedSettingsModule,
    ArmyBuilderSharedModule,
    SettingsSyncService,
    DebugModule,
    EntityPreloadModule,
    CustomLoader
} from 'army-builder-shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SubscriptionModule } from '../subscription/subscription.module';
import { PageLayoutModule } from '../global/page-layout';

import { LoginComponent, LoginInfoComponent } from './user/login.component';
import { UserPage } from './user/user.page';
import { SettingsPage } from './settings.page';
import { SETTINGS_ROUTES } from './settings.routes';
import { SocialSigninService } from './user/service/social-signin.service';

@NgModule({
    declarations: [SettingsPage, UserPage, LoginComponent, LoginInfoComponent],
    imports: [
        CommonModule,
        IonicModule,
        PageLayoutModule,
        RouterModule.forChild(SETTINGS_ROUTES),
        SharedSettingsModule,
        ArmyBuilderSharedModule,
        NgLetModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader
            }
        }),
        SubscriptionModule,
        DebugModule,
        EntityPreloadModule
    ],
    providers: [InAppBrowser, SocialSigninService],
    exports: [LoginComponent]
})
export class WarlordSettingsModule {
    constructor(_settingsSyncService: SettingsSyncService) {}
}
