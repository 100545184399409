import { Component, inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { BehaviorSubject, distinctUntilChanged, filter, map, switchMap } from 'rxjs';

import {
    ArmyBuilderConfig,
    CustomLoader,
    DebugService,
    HttpClientWithInFlightCache,
    LANGUAGE,
    Logger,
    Modal,
    NativeStorageService,
    SelectTranslations,
    SettingsService,
    TRANSLATION_ACTIONS
} from './global';
import { SupportService } from './support';
import { EULAComponent } from './global/settings/eula.modal.component';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-root',
    template: ``
})
export abstract class BaseAppComponent {
    supported = null;
    minClientVersion = null;
    currentClientVersion = null;
    otherClientActive$ = new BehaviorSubject<boolean>(false);
    protected logger: Logger;
    language = LANGUAGE;

    // These should come from package.json
    protected abstract appVersion: string;
    protected abstract clearDataBeforeVersion: string;
    protected abstract gameIds: string[];
    protected abstract customLoader: CustomLoader;

    protected store = inject(Store);
    protected platform = inject(Platform);
    protected http = inject(HttpClientWithInFlightCache);
    protected config = inject(ArmyBuilderConfig);
    protected supportService = inject(SupportService);
    protected storage = inject(NativeStorageService);
    protected debugService = inject(DebugService);
    protected settingsService = inject(SettingsService);
    protected modal = inject(Modal);

    constructor() {
        this.initializeApp();
        this.logger = this.debugService.getInstance('AppComponent');
    }

    private async initializeApp() {
        const previousVersion = await this.storage.getItem('appVersion', null);
        const currentVersion = this.appVersion;

        console.log(`Caching app version ${currentVersion}`);
        await this.storage.setItem('appVersion', currentVersion);

        const timeToClearData = !this.versionCheck(previousVersion, this.clearDataBeforeVersion);
        if (!previousVersion || timeToClearData) {
            console.log('Clearing app data');
            this.storage.clear();
            window.location.reload();
            return;
        }

        return this.platform.ready().then((platform) => {
            if (platform !== 'dom') {
                StatusBar.setStyle({ style: Style.Default });
            }
            SplashScreen.hide();

            this.http.get(`${this.config.apiBaseUrl}/healthcheck`).subscribe((res: any) => {
                this.minClientVersion = res.minClientVersion;
                this.currentClientVersion = currentVersion;
                this.config.environment = res.environment;
                this.supported = this.versionCheck(currentVersion, res.minClientVersion);
                console.log(`Client version ${currentVersion}, API version: ${res.minClientVersion}, supported: ${this.supported}`);
            });

            this.store.select(SelectTranslations).subscribe((data) => {
                let loaded = true;
                let lang = LANGUAGE;
                for (let gameId of this.gameIds) {
                    let lanaguageDataForGame = data[lang]?.[gameId]?.[LANGUAGE] ?? {};
                    let keys = Object.keys(lanaguageDataForGame);
                    if (!keys.length) {
                        loaded = false;
                        break;
                    }
                }
            });

            this.loadTranslations();

            this.init();
            this.checkEULA();
        });
    }

    abstract init();

    loadTranslations() {
        const lang = LANGUAGE;

        for (let gameId of this.gameIds) {
            this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_LOADING({ gameId, lang }));
            this.customLoader.loadData(gameId, LANGUAGE);
        }
    }

    /**
     * Returns true if the client is greater than
     * or equal to the minClient
     */
    versionCheck(client, minClient) {
        const clientVersion = this.getVersionParts(client);
        const minClientVersion = this.getVersionParts(minClient);

        return this.isClientSupported(clientVersion, minClientVersion);
    }

    getVersionParts(version: string) {
        return version?.split('.').map((x) => parseInt(x));
    }

    isClientSupported(client, api) {
        console.log('isClientSupported', { client, api });
        for (let i = 0; i < client?.length; i++) {
            if (client[i] < api[i]) {
                return false;
            }
            if (client[i] > api[i]) {
                return true;
            }
        }
        return true;
    }

    reportProblem(e?) {
        if (e) {
            e.preventDefault();
        }
        this.supportService.showModal();
    }

    checkEULA() {
        this.settingsService.login$
            .pipe(
                filter((l) => !!l),
                switchMap(() => this.settingsService.settings$.pipe(map((s) => s.global?.acceptedEULADate))),
                distinctUntilChanged()
            )
            .subscribe((acceptedEULADate) => {
                console.log({ acceptedEULADate });
                if (!acceptedEULADate) {
                    this.modal.show({
                        component: EULAComponent,
                        props: {}
                    });
                }
            });
    }
}
