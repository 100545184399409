export type TranslationStatus = 'LOADING' | 'LOADED' | 'ERROR';

export interface LanguageState {
    [gameId: string]: {
        status: TranslationStatus;
        data: any;
    };
}
export interface TranslationState {
    showPreloadModal: boolean;
    en: LanguageState;
}
export const initialTranslationState: TranslationState = {
    showPreloadModal: false,
    en: {}
};
