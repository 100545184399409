import { Component, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map } from 'rxjs';

import { ArmyBuilderConfig } from '../../../global/config';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { snapshot } from '../../../global/utils';
import { ForceService } from '../../force.service';
import { ForceUtils } from '../../force.utils';
import { Force, Faction } from '../../models';
import { DataLibrary } from '../../../global/data-library';

@Component({
    selector: 'abs-force-type',
    template: `
        <abs-page-layout [title]="'FORCES.HEADERS.SELECT_FORCE_TYPE' | translate">
            <abs-list
                [items]="factions$ | async"
                groupBy="faction"
                (itemSelect)="itemSelected($event.item)"
                [template]="forceTypeRow"
                [noItemsTextKey]="'FORCES.ERRORS.NO_FACTION_DATA'"
            ></abs-list>
            <ng-template let-item="item" #forceTypeRow>
                <div class="itemName">
                    {{ item.name | language | async }}
                </div>
            </ng-template>
        </abs-page-layout>
    `
})
export class ForceTypePage {
    factions$ = this.dataLibrary.factions$.pipe(map((factions) => [...factions].sort((a, b) => a.name.en.localeCompare(b.name.en))));

    constructor(
        public forceService: ForceService,
        public dataLibrary: DataLibrary,
        public route: ActivatedRoute,
        public router: Router,
        private inputAlert: InputAlert,
        public config: ArmyBuilderConfig,
        private zone: NgZone,
        public translateService: TranslateService,
        public forceUtils: ForceUtils
    ) {}

    getForceFromForceType(forceType: Faction) {
        const defaultForce = this.forceUtils.getDefaultForce(forceType);
        return {
            ...defaultForce,
            id: undefined
        };
    }

    itemSelected(forceType: Faction) {
        const f = this.getForceFromForceType(forceType);
        snapshot(
            combineLatest([
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.TITLE'),
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.MESSAGE'),
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.DEFAULT_FORCE_NAME', forceType)
            ]),
            ([title, message, defaultForceName]) => {
                this.inputAlert.show(title, message, defaultForceName, 'text', true).then((name) => {
                    if (!name) {
                        return;
                    }
                    f.name = name;
                    this.forceService.add(f as Force).subscribe((newForce) => {
                        this.zone.run(() => this.router.navigate(['..', newForce.id], { relativeTo: this.route, replaceUrl: true }));
                    });
                });
            }
        );
    }
}
