import { Observable } from 'rxjs';
import { catchError, defaultIfEmpty, map, shareReplay } from 'rxjs/operators';
import { AppInjector, ArmyBuilderConfig, Translation, HttpClientWithInFlightCache } from '../../global';
import { FieldOption } from '../form-controls/form-field';

let httpClient: HttpClientWithInFlightCache;
let appConfig, initialised;

let init = () => {
    if (!initialised) {
        httpClient = AppInjector.get(HttpClientWithInFlightCache) as HttpClientWithInFlightCache;
        appConfig = AppInjector.get(ArmyBuilderConfig);
        initialised = true;
    }
};

interface KeyNameItem {
    key?: string;
    name: Translation | string;
}
export type OptionableItem = 'string' | KeyNameItem;

let itemsFromUrlCache = {};

export const getItemsFromUrl = (url: string, cache = true): Observable<OptionableItem[]> => {
    init();
    if (cache && itemsFromUrlCache[url]) {
        return itemsFromUrlCache[url];
    }

    let res = httpClient.get(url, { headers: { ...appConfig.globalRequestHeaders }, withCredentials: true, requiresLogin: true }).pipe(
        map((res: any) => res.items || []),
        shareReplay(1),
        catchError(() => []),
        defaultIfEmpty([])
    );

    if (cache) {
        itemsFromUrlCache[url] = res;
    }

    return res;
};

export const getOptionsFromUrl = (url: string): Observable<FieldOption[]> => {
    return getItemsFromUrl(url).pipe(mapToOptions());
};

export function mapToOptions() {
    return function (source: Observable<OptionableItem[]>): Observable<FieldOption[]> {
        return source.pipe(
            map((res) =>
                res.map((item) => {
                    if (typeof item == 'string') {
                        return { value: item, label: item };
                    }
                    return { value: item?.key, label: typeof item?.name === 'string' ? item?.name : item?.name?.en, data: item };
                })
            )
        );
    };
}

export function mapStringArrayToOptions(values: string[], sort = true): FieldOption[] {
    return values
        .filter((x, i, all) => all.indexOf(x) === i)
        .filter((x) => x)
        .sort((a, b) => (sort ? a.localeCompare(b) : 0))
        .map((x) => ({ label: x, value: x }));
}
