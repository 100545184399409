import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { capitalise, getLibraryData, SelectTranslations, TRANSLATION_ACTIONS } from 'army-builder-shared';
import { debounceTime, BehaviorSubject } from 'rxjs';
import { EntityLibraryLoadStatus } from '../../army-builder-entity-store';

@Component({
    selector: 'abs-translation-preload',
    template: `
        <ion-list>
            <ion-item *ngFor="let gameId of gameIds">
                Loading language data for {{ descriptions[gameId] }}
                @if (languageDataLoadingState[gameId] | async; as languageStatus) {
                    <ion-spinner *ngIf="languageStatus === 'LOADING'" [slot]="'end'"></ion-spinner>
                    <ion-icon *ngIf="languageStatus === 'LOADED'" name="checkmark-circle-outline" [slot]="'end'"></ion-icon>

                    <ng-container *ngIf="languageStatus === 'ERROR'">
                        <ion-button [size]="'small'" [slot]="'end'" (click)="retry.emit({ gameId, language })">Retry</ion-button>
                        <ion-icon name="close-circle-outline" [slot]="'end'"></ion-icon>
                    </ng-container>
                } @else {
                    <ion-spinner [slot]="'end'"></ion-spinner>
                }
            </ion-item>

            @if (erroredKeys.length > 0) {
                <p>
                    If the retry button does not work, check your network connection or reinstall the app. If you still have issues, contact
                    <a href="mailto:warlord@maloric.com">warlord&#64;maloric.com</a>.
                </p>
                <div class="buttons">
                    <ion-button (click)="retryAll()">Retry all <ion-icon name="refresh" [slot]="'end'"></ion-icon></ion-button>
                    <ion-button (click)="cancel()">Cancel</ion-button>
                </div>
            }

            <div class="buttons" *ngIf="erroredKeys.length === 0">
                <ion-button (click)="close()">Close</ion-button>
            </div>
        </ion-list>
    `
})
export class TranslationPreloadComponent implements OnChanges {
    descriptions: { [key: string]: string } = {};

    @Input()
    gameIds: string[];

    @Input()
    language: string;

    @Output()
    retry = new EventEmitter<{ gameId: string; language: string }>();

    languageDataLoadingState: {
        [key: string]: BehaviorSubject<EntityLibraryLoadStatus>;
    } = {};

    erroredKeys: string[] = [];

    sub = this.store
        .select(SelectTranslations)
        .pipe(debounceTime(100))
        .subscribe((ts) => {
            if (!ts || !this.gameIds) {
                return null;
            }

            let languageData = ts[this.language];
            if (!languageData) {
                // Needs to either throw an error or load language data
                return null;
            }

            for (let gameId of this.gameIds) {
                let gameData = languageData[gameId];
                if (!gameData) {
                    // Needs to either throw an error or load language data
                    return null;
                }
                const status = gameData.status;
                if (!this.languageDataLoadingState[gameId]) {
                    this.languageDataLoadingState[gameId] = new BehaviorSubject<EntityLibraryLoadStatus>(status);
                } else {
                    this.languageDataLoadingState[gameId].next(status);
                }
            }

            this.erroredKeys = Object.keys(ts[this.language]).filter((gameId) => ts[this.language][gameId]?.status === 'ERROR');
        });

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.gameIds.forEach((gameId) => {
            if (!this.descriptions[gameId]) {
                this.descriptions[gameId] = capitalise(gameId.replace(/\-/g, ' ').replace(/_/g, ' '), true);
            }
        });
    }

    retryAll() {
        for (let gameId of this.erroredKeys) {
            this.retry.emit({ gameId: gameId, language: this.language });
        }
    }

    cancel() {
        this.store.dispatch(TRANSLATION_ACTIONS.HIDE_TRANSLATION_PRELOAD_MODAL());
        for (let gameId of this.gameIds) {
            this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_CLEAR_ERROR({ gameId, lang: this.language }));
        }
    }

    close() {
        this.store.dispatch(TRANSLATION_ACTIONS.HIDE_TRANSLATION_PRELOAD_MODAL());
    }
}
