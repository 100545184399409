import { TranslateLoader } from '@ngx-translate/core';
import { catchError, filter, forkJoin, map, Observable, of, shareReplay, tap } from 'rxjs';
import { HttpClientWithInFlightCache } from '../httpClient';
import { Store } from '@ngrx/store';
import { Inject, inject, Injectable, Injector, Optional } from '@angular/core';
import { ArmyBuilderConfig } from '../config';
import { SelectTranslations, TRANSLATION_ACTIONS } from '../state';
import { GAME_ID, RestDataService } from 'army-builder-shared';

@Injectable({ providedIn: 'root' })
export class CustomLoader extends TranslateLoader {
    protected translationsByGameId: any = {};

    private initialized = false;

    protected config: ArmyBuilderConfig;
    protected restDataService: RestDataService;
    protected store: Store;

    constructor(
        @Inject(GAME_ID) public gameId: string,
        // protected config: ArmyBuilderConfig,
        // protected restDataService: RestDataService,
        // protected store: Store,
        private injector: Injector
    ) {
        console.log('CustomLoader: Custom Loader constructor', gameId);
        super();
    }

    loadData(gameId: string, lang: string, fallbackToCache = true) {
        console.log('CustomLoader: CustomLoader.loadData', gameId);
        this.init();

        let apiBaseUrl = this.config.apiBaseUrl;
        const url = `${apiBaseUrl}/translation/${gameId}/${lang}`;

        this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_LOADING({ gameId, lang }));
        this.restDataService
            .get(url, {
                cacheKey: `translation_${gameId}_${lang}`,
                preferCache: false,
                fallbackToCache
            })
            .pipe(
                // defaultIfEmpty({}),
                map((x: any) => {
                    return x.value;
                }),
                catchError((err) => {
                    console.error(`Error loading translations from ${url}:`, gameId, err);
                    setTimeout(() => {
                        this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_ERROR({ gameId, lang }));
                    }, 1000);
                    throw err;
                }),
                shareReplay(1)
            )
            .subscribe((data) => {
                console.log(`CustomLoader: Translations loaded (${gameId}:${lang})`, data);
                console.log('CustomLoader: Translations: A new value was pushed to the store');
                this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_LOADED({ gameId, lang, data }));
            });
    }

    private init() {
        if (!this.initialized) {
            this.config = this.injector.get(ArmyBuilderConfig);
            this.restDataService = this.injector.get(RestDataService);
            this.store = this.injector.get(Store);
            this.initialized = true;
        }
    }

    public getTranslation(lang: string): Observable<any> {
        console.log('CustomLoader: CustomLoader.getTranslation');

        console.log('CustomLoader: Translations: getTranslation was called for ' + lang);
        this.init();
        const gameId = this.gameId;
        if (!this.translationsByGameId[gameId]) {
            this.translationsByGameId[gameId] = {};
        }

        if (!this.translationsByGameId[gameId][lang]) {
            this.translationsByGameId[gameId][lang] = this.store.select(SelectTranslations).pipe(
                map((translations) => {
                    console.log('CustomLoader: Translations: A new value was pushed from the store');
                    const languageTranslations = translations?.[lang];
                    const gameTranslations = languageTranslations?.[gameId]?.data;
                    const globalTranslations = languageTranslations?.['global']?.data;

                    if (!gameTranslations) {
                        return {};
                    }

                    const res = {
                        ...gameTranslations,
                        GLOBAL: {
                            ...globalTranslations,
                            ...gameTranslations.GLOBAL
                        }
                    };

                    return res;
                }),
                filter((res) => Object.keys(res)?.length > 0),
                tap((x) => console.log('CustomLoader: Translations has a value at last', x)),
                catchError((err) => {
                    setTimeout(() => {
                        this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_ERROR({ gameId, lang }));
                    }, 1000);
                    return of({});
                })
            );
        }

        return this.translationsByGameId[gameId][lang];
    }
}
