import { Component, Input } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { SettingsService, UserService } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'abs-eula',
    template: `
        <abs-page-layout title="End User License Agreement">
            <div class="warning" *ngIf="acceptedDate$ | async as acceptedDate">
                You accepted this agreement on {{ acceptedDate | date }}.
            </div>

            <abs-content [path]="'eula'"></abs-content>
        </abs-page-layout>
    `,
    styles: [
        `
            .warning {
            }
        `
    ]
})
export class EULAPage {
    acceptedDate$: Observable<Date> = this.settingsService.login$.pipe(
        filter((l) => !!l),
        switchMap(() => this.settingsService.settings$.pipe(map((s) => s.global?.acceptedEULADate))),
        distinctUntilChanged()
    );

    @Input()
    cssClass: string;

    constructor(
        private iab: InAppBrowser,
        private settingsService: SettingsService
    ) {}

    openLink(url: string, e: any) {
        e.preventDefault();
        this.iab.create(url, '_system');
    }
}
