import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE, TRANSLATION_ACTIONS } from 'army-builder-shared';
import { debounceTime, firstValueFrom, map, of, switchMap } from 'rxjs';

@Injectable()
export class TranslationEffects {
    interceptAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TRANSLATION_ACTIONS.TRANSLATION_LOADED),
                map(async (action: any) => {
                    const { gameId, dataType, data } = action;

                    console.log('Reloading translations for', gameId, dataType);
                    await firstValueFrom(this.translateService.reloadLang(LANGUAGE));
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private translateService: TranslateService
    ) {}
}
