import { ForceState } from '../state';

export const addUnitOptionReducer = (
    state: ForceState,
    { forceId, unitId, optionId }: { forceId: string; unitId: string; optionId: number }
): ForceState => ({
    ...state,
    forces: state.forces.map((f) => {
        if (f.id !== forceId) {
            return f;
        }
        return {
            ...f,
            units: f.units.map((u) => {
                if (u.id !== unitId) {
                    return u;
                }

                const selectedOptions = [...u.selectedOptions, optionId];
                return {
                    ...u,
                    selectedOptions
                };
            })
        };
    })
});
