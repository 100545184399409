import { NgModule } from '@angular/core';

import { TranslationPreloadComponent } from './translation-preload.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';
import { TranslationPreloadModalComponent } from './translation-preload-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { TranslationEffects } from './translation.effects';

@NgModule({
    imports: [CommonModule, IonicModule, NgLetModule, StoreModule, TranslateModule, EffectsModule.forFeature(TranslationEffects)],
    exports: [TranslationPreloadComponent, TranslationPreloadModalComponent],
    declarations: [TranslationPreloadComponent, TranslationPreloadModalComponent],
    providers: []
})
export class TranslationPreloadModule {}
