import { Injectable, NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, shareReplay, switchMap, take, tap, timeout } from 'rxjs/operators';

import { ArmyBuilderConfig } from './config';
import { SettingsService } from './settings';

export interface HttpClientRequestOptions {
    requiresLogin?: boolean;
    withCredentials?: boolean;
    headers?: any;
    [key: string]: any;
}

const inFlightRequests: { [key: string]: Observable<any> } = {};

@Injectable()
export class HttpClientWithInFlightCache {
    constructor(
        private config: ArmyBuilderConfig,
        private httpClient: HttpClient,
        private settingsService: SettingsService
    ) {}

    private request(method: 'get' | 'post' | 'put' | 'patch' | 'delete', url: string, body: any, opts: HttpClientRequestOptions) {
        if (method === 'get' && inFlightRequests[url]) {
            // console.log('Reusing existing request for ' + url);
            return inFlightRequests[url];
        }

        const req = combineLatest([this.config.device$, this.settingsService.login$]).pipe(
            filter(([device, l]) => {
                return !!device?.id && (!opts?.requiresLogin || l.user);
            }),
            take(1),
            switchMap(([device, l]) => {
                opts = {
                    ...opts,
                    headers: {
                        ...opts?.headers,
                        device: JSON.stringify(device),
                        appVersion: this.config.version
                    }
                };
                if (opts.withCredentials !== false) {
                    if (l.user?.accessToken) {
                        opts.headers.Authorization = 'Bearer ' + l.user?.accessToken;
                    }
                }
                switch (method) {
                    case 'get':
                        return this.httpClient.get(url, {
                            ...opts,
                            withCredentials: opts?.withCredentials ?? true
                        });
                    case 'delete':
                        return this.httpClient.delete(url, {
                            ...opts,
                            withCredentials: opts?.withCredentials ?? true
                        });
                    default:
                        return this.httpClient[method](url, body, {
                            ...opts,
                            withCredentials: opts?.withCredentials ?? true
                        });
                }
            }),
            timeout(this.config.networkTimeout),
            shareReplay(1)
        );
        if (method === 'get') {
            inFlightRequests[url] = req;
            req.pipe(catchError(() => of(null))).subscribe(() => {
                delete inFlightRequests[url];
            });
        }
        return req;
    }

    get(url: string, opts?: HttpClientRequestOptions): Observable<any> {
        return this.request('get', url, null, opts);
    }

    post(url: string, body: any, opts?: HttpClientRequestOptions): Observable<any> {
        return this.request('post', url, body, opts);
    }

    put(url: string, body: any, opts?: HttpClientRequestOptions): Observable<any> {
        return this.request('put', url, body, opts);
    }

    patch(url: string, body: any, opts?: HttpClientRequestOptions): Observable<any> {
        return this.request('patch', url, body, opts);
    }

    delete(url: string, body?: any, opts?: HttpClientRequestOptions): Observable<any> {
        return this.request('delete', url, body, opts);
    }
}

@NgModule({
    exports: [],
    declarations: [],
    imports: [],
    providers: [HttpClientWithInFlightCache, provideHttpClient(withInterceptorsFromDi())]
})
export class HttpClientWithInFlightCacheModule {}
