import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sharedForcesLoaded } from '../../forces/shared-forces.actions';
import { ArmyBuilderConfig } from '../config';
import { HttpClientWithInFlightCache } from '../httpClient';
import { NativeStorageService } from '../data';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SharingService {
    constructor(
        private httpClient: HttpClientWithInFlightCache,
        private config: ArmyBuilderConfig,
        private store: Store,
        private storageService: NativeStorageService
    ) {}

    public async getSharedForces(gameId: string) {
        const url = `${this.config.apiBaseUrl}/userData/forces/shared/${gameId}`;
        let headers: any = {};

        let impersonate = await this.storageService.getItem('impersonate', null);
        if (impersonate) {
            headers.impersonate = impersonate;
        }

        return firstValueFrom(this.httpClient.get(url, { headers })).then((res) => {
            this.store.dispatch(sharedForcesLoaded({ gameId, forces: res }));
        });
    }
}
