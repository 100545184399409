import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { FormField, FormSectionField, NamedFormField } from '../form-controls/form-field';
import { createTranslatableTagsFormGroup } from '../form-controls/translatable-tags/functions';

export interface FormConfig {
    dryRun?: boolean;
    formDefinition?: FormField[];
    raw?: boolean;
    defaultValue?: any;
}

const getSectionFields = (formDefinition: FormField[]) =>
    formDefinition
        .filter((x) => x.type === 'section')
        .reduce((agg, s: FormSectionField) => [...agg, ...s.fields, ...getSectionFields(s.fields)], []);

export const createForm = (formDefinition: FormField[]) => {
    let sectionFields = getSectionFields(formDefinition);
    return new UntypedFormGroup(
        [...formDefinition, ...sectionFields].reduce((agg, item) => {
            if (item.type === 'section') {
                return agg;
            }
            switch (item.type) {
                case 'translation':
                case 'html':
                    if (item.multi) {
                        agg[item.name] = new UntypedFormArray([]);
                    } else {
                        agg[item.name] = new UntypedFormGroup({
                            en: new UntypedFormControl()
                        });
                    }

                    break;
                case 'array':
                case 'translatableTags':
                case 'complexTags':
                case 'tags':
                    agg[item.name] = new UntypedFormArray([]);
                    break;
                case 'group':
                    agg[item.name] = createForm(item.fields);
                    break;
                default:
                    if (item.multi && item.type !== 'select') {
                        agg[item.name] = new UntypedFormArray([]);
                    } else {
                        agg[item.name] = new UntypedFormControl();
                    }
                    break;
            }
            return agg;
        }, {})
    );
};

// Recursively create the correct number of form controls for FormArray controls
export const patchForm = (form: UntypedFormGroup, formDefinition: FormField[], data) => {
    let combinedFormDefinition: FormField[] = [...formDefinition, ...getSectionFields(formDefinition)];
    let arrayFields = Object.entries(form.controls).filter((x) => x[1] instanceof UntypedFormArray);

    arrayFields.forEach(([key, value]) => {
        let formArray = form.get(key) as UntypedFormArray;
        let fieldDef = combinedFormDefinition.find((x: NamedFormField) => x.name === key);
        let hasSubFields = fieldDef.type === 'array' || fieldDef.type === 'section' || fieldDef.type === 'group';

        let formValue: any[] = data?.[key];
        if ((!formValue || formValue.length === 0) && fieldDef.type === 'array' && fieldDef.defaultValue) {
            formValue = fieldDef.defaultValue;
        }

        let arrayCount = formValue?.length || 0;

        for (let i = 0; i < arrayCount; i++) {
            if (formArray.controls.length >= arrayCount) {
                break;
            }

            let subForm;
            if (fieldDef.type === 'translatableTags') {
                subForm = createTranslatableTagsFormGroup();
                patchForm(
                    subForm,
                    [
                        {
                            type: 'hidden',
                            name: 'key'
                        },
                        {
                            type: 'hidden',
                            name: 'en'
                        }
                    ],
                    formValue[i]
                );
            } else if (
                fieldDef.type === 'array' ||
                fieldDef.type === 'section' ||
                fieldDef.type === 'group' ||
                fieldDef.type === 'complexTags'
            ) {
                subForm = createForm(fieldDef.fields);
                patchForm(subForm, fieldDef.fields, formValue[i]);
            } else {
                subForm = new UntypedFormControl();
            }

            formArray.push(subForm);
        }
    });

    let groupFields = Object.entries(form.controls).filter((x) => x[1] instanceof UntypedFormGroup);
    groupFields.forEach(([key, value]) => {
        if (key === 'undefined') {
            return;
        }
        let formGroup = form.get(key) as UntypedFormGroup;
        let fieldDef = combinedFormDefinition.find((x: any) => x.name === key);
        if (!(fieldDef.type === 'array' || fieldDef.type === 'section' || fieldDef.type === 'group')) {
            return;
        }

        patchForm(formGroup, fieldDef.fields, data?.[key]);
    });

    for (let field of formDefinition as NamedFormField[]) {
        if (!data?.[field.name] && field.defaultValue) {
            data[field.name] = field.defaultValue;
        }
    }

    form.patchValue(data);

    return form;
};
