import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ArmyBuilderConfig, LoginSelector } from 'army-builder-shared';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, map, switchMap } from 'rxjs';

import { SubscriptionService } from '../subscription/subscription.service';
import { WarlordUserService } from '../settings/user/user.service';
import { PaywallComponent } from '../subscription/paywall.component';
import { environment } from 'warlord/src/environments/environment';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
    selector: 'wl-home',
    template: `
        <ng-container
            *ngLet="{
                loginState: login$ | async,
                hasAccess: userHasAccess$ | async,
                loginChecked: loginChecked$ | async,
                loggedInMessage: loggedInMessage$ | async,
                loggedOutMessage: loggedOutMessage$ | async,
                expiry: expiry$ | async,
                expired: expired$ | async
            } as props"
        >
            <ion-toolbar>
                <ion-buttons slot="end">
                    <ion-menu-button *ngIf="props.loginState.user"></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
            <div class="scrollWrapper">
                <div class="homeWrapper">
                    <img src="/assets/images/warlord.png" alt="Warlord" class="warlordLogo" (click)="logoClicked()" />

                    <div class="menu logos home">
                        <div *ngIf="platform === 'web'" class="native">
                            <a href="https://play.google.com/store/apps/details?id=com.warlordgames.app&gl=GB"
                                ><img src="/assets/images/google-play.png" [alt]="'GLOBAL.HOME.GOOGLE' | translate"
                            /></a>
                            <a href="https://apps.apple.com/us/app/warlord-games-list-builder/id1637349658"
                                ><img src="/assets/images/app-store.png" [alt]="'GLOBAL.HOME.APPLE' | translate"
                            /></a>
                            <ion-button *ngIf="showOfflineLogin" (click)="offlineLogin()">TEST LOGIN</ion-button>
                        </div>

                        @if (props.loginState?.user?.roles?.includes('app_user')) {
                            <ion-card>
                                <ion-card-content [innerHTML]="props.loggedInMessage"></ion-card-content>
                            </ion-card>

                            @if (props.expired) {
                                <ion-card color="danger">
                                    <ion-card-content> Your subscription expired {{ props.expiry | relativeDate }}. </ion-card-content>
                                </ion-card>
                            }

                            @if (props.hasAccess) {
                                <wl-game-list></wl-game-list>
                            } @else {
                                <ion-button (click)="subscribe()">Subscribe</ion-button>
                            }
                        } @else {
                            <ion-card>
                                <p *ngIf="props.loggedOutMessage" style="font-weight:bold">
                                    {{ props.loggedOutMessage }}
                                </p>
                            </ion-card>
                        }

                        <wl-login
                            [showInfo]="false"
                            [showLogin]="logoClickCount >= 30"
                            [showGuest]="true"
                            [showRegister]="platform !== 'ios'"
                        >
                        </wl-login>

                        <p class="eula iosOnly">
                            <a (click)="openLink('https://www.apple.com/legal/internet-services/itunes/dev/stdeula/')">EULA</a> |
                            <a (click)="openLink('https://store.warlordgames.com/policies/privacy-policy')">Privacy Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    `,
    styles: [
        `
            :host {
                /* background: black url(/assets/images/banner2.jpg) 0 0 / cover repeat-x; */
                /*background: #3c3c3c;*/
                /* animation: slide 10000s linear infinite; */
                position: relative;
                height: 100%;
                background-color: #000;
            }

            :host:before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.4;
                background-image: url(/assets/images/banner2.jpg);
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: cover;
            }

            .scrollWrapper {
                /* needs a separate wrapper to fix a bug with ios where the logo is rendered too high up the page */
                overflow: auto;
                flex: 1 1 auto;
            }

            .homeWrapper {
                min-height: 100%;
                padding-top: 200px;
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: space-around;
            }

            ion-toolbar {
                --ion-toolbar-background: none;
                --background: none;
                background: none;
                padding-top: var(--ion-safe-area-top, 0);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }

            @keyframes slide {
                from {
                    background-position: 0 0;
                }
                to {
                    background-position: -250000px 0;
                }
            }

            .warlordLogo {
                margin: 0 auto;
                flex: 0 1 auto;
                max-width: 229px;
            }

            .menu {
                flex: 0 0 auto;
                padding: 40px 10px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                max-width: 520px;
                width: 100%;
                align-self: center;
            }

            ion-card {
                margin-inline: 2px;
                padding: 0 10px;
            }
            .native {
                display: flex;
                justify-content: center;
            }
            .native a {
                text-decoration: none;
            }
            .native img {
                height: 70px;
            }

            ion-menu-button {
                color: #eee;
            }

            .eula,
            .eula a {
                color: white;
                font-size: 0.85em;
            }
        `
    ]
})
export class HomePage {
    showOfflineLogin = environment.showOfflineLogin;
    login$ = this.store.pipe(select(LoginSelector));
    loginChecked$ = this.userService.loginChecked$;
    userHasAccess$ = this.subscriptionService.userHasAccess$;
    expiry$ = this.subscriptionService.expiry$;
    expired$ = this.expiry$.pipe(map((exp) => exp && exp < new Date()));
    platform = 'web';
    logoClickCount = 0;

    loggedInMessage$ = this.login$.pipe(
        switchMap((l) => this.translateService.get('GLOBAL.LOGIN.LOGGED_IN_MESSAGE', l.user)),
        map((msg) => (msg === 'GLOBAL.LOGIN.LOGGED_IN_MESSAGE' ? '' : msg)),
        distinctUntilChanged()
    );

    loggedOutMessage$ = this.translateService
        .get('GLOBAL.LOGIN.LOGGED_OUT_MESSAGE')
        .pipe(map((msg) => (msg === 'GLOBAL.LOGIN.LOGGED_OUT_MESSAGE' ? '' : msg)));

    constructor(
        public userService: WarlordUserService,
        private subscriptionService: SubscriptionService,
        private store: Store,
        platform: Platform,
        private translateService: TranslateService,
        private modalController: ModalController,
        protected config: ArmyBuilderConfig,
        private iab: InAppBrowser
    ) {
        if (platform.is('ios') && !platform.is('mobileweb')) {
            this.platform = 'ios';
        } else if (platform.is('android') && !platform.is('mobileweb')) {
            this.platform = 'android';
        }
    }

    logoClicked() {
        this.logoClickCount += 1;
    }

    getMessage(key) {
        return;
    }

    logout() {
        this.userService.logout();
    }

    subscribe() {
        this.modalController
            .create({
                component: PaywallComponent
            })
            .then((m) => m.present());
    }

    offlineLogin() {
        this.userService.login({ token: 'Test' });
    }

    openLink(url: string) {
        this.iab.create(url);
    }
}
