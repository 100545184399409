import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, signal } from '@angular/core';
import { SettingsService } from 'army-builder-shared/src/lib';
import { map } from 'rxjs';
import { StoreAccountComponent } from './store-account.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { WarlordUserService } from '../settings/user/user.service';
import { IonicModule } from '@ionic/angular';
import { ToastService } from 'army-builder-shared/src/lib/global/toast/toast';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
    selector: 'voucher-details',
    imports: [CommonModule, IonicModule, StoreAccountComponent],
    template: `
        <div>
            <p>
                As an annual subcriber, you are eligible to receive a discount voucher to be used on the Warlord web store.
                @if (!storeAccountLinked()) {
                    Before you can redeem your voucher, you need to link your Warlord web store account with the app.
                }
            </p>
            <store-account></store-account>

            @if (storeAccountLinked()) {
                @if (voucherCode()) {
                    <div>
                        <div class="voucherHeader">
                            <ion-label>Voucher Code</ion-label>
                            <span *ngIf="showStoreLink">Copied!</span>
                        </div>
                        <div class="voucherCode">
                            <span class="value" (click)="copyVoucher()">
                                {{ voucherCode() }}
                            </span>
                            <ion-icon name="copy-outline" class="copy" (click)="copyVoucher()"></ion-icon>

                            <div class="storeLink" *ngIf="showStoreLink" (click)="hideStoreLink()">
                                <ion-button (click)="goToStore()">Warlord Web Store</ion-button>
                            </div>
                        </div>
                    </div>
                    <div class="voucherFooter">
                        <span *ngIf="voucherStatus() as status" [class]="status | lowercase">Voucher Status: {{ status }}</span>
                        @if (voucherExpiry(); as expiry) {
                            <span> Expires on {{ expiry | date }}</span>
                        }
                    </div>
                }

                <div class="error" *ngIf="error">{{ error }}</div>
                @if (!voucherCode() || voucherStatus() === 'Invalid code' || voucherStatus() === 'Expired') {
                    <div class="buttons">
                        <ion-button (click)="generateVoucher()">Generate Voucher <ion-spinner *ngIf="generating"></ion-spinner></ion-button>
                    </div>
                }
            }
        </div>
    `,
    styles: [
        `
            .voucherCode {
                font-weight: bold;
                font-size: 1.5em;
                text-align: center;
                padding: 20px;
                border: 1px solid #ccc;
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;
            }

            .voucherCode .value {
                flex: 1 1 auto;
            }

            .voucherCode .copy {
                flex: 0 0 auto;
                position: absolute;
                right: 20px;
            }

            .voucherCode .storeLink {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .voucherFooter,
            .voucherHeader {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                font-size: 0.8em;
            }

            .voucherFooter .invalid,
            .voucherFooter .expired {
                color: red;
            }
            .voucherFooter .active {
                color: green;
            }
        `
    ],
    standalone: true
})
export class VoucherDetailsComponent {
    settingsService = inject(SettingsService);
    user$ = this.settingsService.login$.pipe(map((l) => l.user));
    stores$ = this.user$.pipe(map((u) => Object.keys(u.stores || {}).filter((key) => !!u.stores[key])));

    user = toSignal(this.user$);
    metadata = computed(() => this.user()?.metadata);
    settings = toSignal(this.settingsService.settings$);
    toast = inject(ToastService);
    webstoreEmail = computed(() => this.settings().global?.webstoreEmail);
    preferredStore = computed(() => this.settings().global?.preferredStore);
    webstoreCustomerId = computed(() => this.settings().global?.webstoreCustomerId);
    voucherCode = computed(() => this.metadata()?.voucherCode);
    lastVoucherDate = computed(() => this.metadata()?.lastVoucherDate);
    showStoreLink = false;

    voucherDetails = signal(null);
    voucherStatus = computed(() => {
        let state = this.voucherDetails()?.voucher;
        console.log('Voucher state', state);

        if (!state) {
            return 'Loading...';
        }

        if (!state?.valid_code) {
            return 'Invalid code';
        }

        if (state?.used_code) {
            return 'Used';
        }

        if (!state?.active_code) {
            return 'Expired';
        }

        return 'Active';
    });
    voucherExpiry = computed(() => {
        let state = this.voucherDetails()?.voucher;

        if (!state) {
            return 'Loading...';
        }

        return state.ends_at;
    });

    storeAccountLinked = computed(() => this.webstoreEmail() && this.preferredStore() && this.webstoreCustomerId());

    userService = inject(WarlordUserService);
    error: string;
    generating = false;

    async generateVoucher() {
        this.generating = true;
        let res = await this.userService
            .generateVoucher()
            .then((res) => {
                this.error = null;
                this.generating = false;
                return res;
            })
            .catch((err) => {
                console.error(err);
                this.error = err?.error?.message;
                this.generating = false;
            });

        if (res) {
            this.error = null;
            this.settingsService.updateUserMetadataValue('voucherCode', res);
        }
    }

    copyVoucher() {
        navigator.clipboard.writeText(this.voucherCode());
        this.toast.showToast('', 'Copied voucher to clipboard', { color: 'success', duration: 3000 });
        this.showStoreLink = true;
    }

    hideStoreLink() {
        this.showStoreLink = false;
    }

    constructor(private iab: InAppBrowser) {
        effect(async () => {
            let vc = this.voucherCode();
            if (!vc) {
                return null;
            }

            let attempts = 0;
            const updateVoucherDetails = async () => {
                attempts++;
                let voucherDetails = await this.userService.validateWarlordVoucher(vc);
                console.log({ voucherDetails });
                if (!voucherDetails?.voucher?.valid_code && attempts < 5) {
                    setTimeout(() => {
                        updateVoucherDetails();
                    }, 2000);
                } else {
                    this.voucherDetails.set(voucherDetails);
                }
            };
            updateVoucherDetails();
        });
    }

    goToStore() {
        const store = this.preferredStore()?.toLowerCase() || 'uk';
        const url = `https://store.warlordgames.com/MALORICDIGITAL?store=${store}&utm_source=collabs&utm_medium=web&utm_campaign=maloricdigital`;
        this.iab.create(url);
    }
}
