import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { UserService as BaseUserService } from 'army-builder-shared';
import { catchError, firstValueFrom, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WarlordUserService extends BaseUserService {
    settings = toSignal(this.settingsService.settings$);
    user = computed(() => this.settings().global?.login?.user);

    async getWebstoreCustomerDetails({ email, store }: { email: string; store: string; errorCode?: number }) {
        const linkStoreAccountUrl = this.config.apiBaseUrl + '/shop/customer/validate';
        let res = await firstValueFrom(
            this.httpClient
                .post(linkStoreAccountUrl, { email, store })
                .pipe(catchError((err) => of({ customerId: 0, errorCode: err.status })))
        );
        console.log(res);
        return res;
    }

    async generateVoucher() {
        let { voucherCode } = await firstValueFrom(this.httpClient.post(this.config.apiBaseUrl + '/shop/voucher/generate', {}));
        return voucherCode;
    }

    async validateWarlordVoucher(voucherCode: string) {
        let voucherDetails = await firstValueFrom(this.httpClient.post(this.config.apiBaseUrl + '/shop/voucher/validate', { voucherCode }));
        return voucherDetails;
    }
}
