import { Component } from '@angular/core';
import { ArmyBuilderConfig, HttpClientWithInFlightCache } from 'army-builder-shared';
import { Observable, switchMap } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'users-page',
    template: `
        <abs-page-layout title="Users">
            <div class="content">
                <form [formGroup]="formGroup">
                    <div class="form-group">
                        <ion-label>Find User by Email</ion-label>
                        <ion-input (keyup.enter)="onEnter($event)" [formControlName]="'email'"></ion-input>
                        <span class="error" *ngIf="formGroup.errors?.length > 0"> Required</span>
                    </div>
                    <div class="buttons">
                        <ion-button (click)="submit()">Search</ion-button>
                    </div>
                </form>
            </div>

            <ng-template #rowTemplate let-item="item">
                <div class="itemWrap">{{ item.email }}</div>
            </ng-template>

            <div *ngIf="user$ | async as user">
                <a [href]="'https://app.revenuecat.com/customers/' + appConfig.revenueCatAppId + '/' + user._id">View in RevenueCat</a>
                <pre>{{ user | json }}</pre>
                <h2>Entitlements</h2>
                <pre>{{ userEntitlements$ | async | json }}</pre>
            </div>
        </abs-page-layout>
    `
})
export class UserLookupPage {
    usersUrl = this.appConfig.apiBaseUrl + '/user/lookup/';
    entitlementsUrl = this.appConfig.apiBaseUrl + '/user/entitlements/';
    user$: Observable<any>;
    userEntitlements$: Observable<any>;

    constructor(
        private fb: FormBuilder,
        private httpClient: HttpClientWithInFlightCache,
        protected appConfig: ArmyBuilderConfig
    ) {}

    formGroup: FormGroup = this.fb.group({
        email: this.fb.control('', Validators.required)
    });

    async submit() {
        if (this.formGroup.errors?.length > 0) {
            return;
        }

        const { email } = this.formGroup.value;

        // await firstValueFrom(this.httpClient.post(this.usersUrl, { email }));
        this.lookupUpUser(email);
    }

    lookupUpUser(email: string) {
        this.user$ = this.httpClient.get(this.usersUrl + email, {
            headers: { ...this.appConfig.globalRequestHeaders },
            withCredentials: true,
            requiresLogin: true
        });

        this.userEntitlements$ = this.user$.pipe(
            switchMap((user) =>
                this.httpClient.get(this.entitlementsUrl + user._id, {
                    headers: { ...this.appConfig.globalRequestHeaders },
                    withCredentials: true,
                    requiresLogin: true
                })
            )
        );
    }

    onEnter(e: any) {
        this.lookupUpUser(e.target.value);
    }
}
