import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { TRANSLATION_ACTIONS } from './translation.actions';
import { initialTranslationState, TranslationState } from './initialTranslationState';

export const translationReducer = createReducer(
    initialTranslationState,
    on(TRANSLATION_ACTIONS.TRANSLATION_LOADING, (state: TranslationState, { lang, gameId }) => {
        let res: TranslationState = {
            ...state,
            [lang]: {
                ...(state?.[lang] ?? {}),
                [gameId]: {
                    ...(state?.[lang]?.[gameId]?.data ?? {}),
                    status: 'LOADING'
                }
            }
        };
        return res;
    }),
    on(TRANSLATION_ACTIONS.TRANSLATION_LOADED, (state: TranslationState, { gameId, lang, data }) => {
        let res: TranslationState = {
            ...state,
            [lang]: {
                ...(state?.[lang] ?? {}),
                [gameId]: {
                    data,
                    status: 'LOADED'
                }
            }
        };
        return res;
    }),
    on(TRANSLATION_ACTIONS.TRANSLATION_CLEAR_ERROR, (state: TranslationState, { gameId, lang }) => {
        let res: TranslationState = {
            ...state,
            [lang]: {
                ...(state?.[lang] ?? {}),
                [gameId]: {
                    ...(state?.[lang]?.[gameId]?.data ?? {}),
                    status: 'LOADED'
                }
            }
        };
        return res;
    }),
    on(TRANSLATION_ACTIONS.TRANSLATION_ERROR, (state: TranslationState, { lang, gameId }) => {
        let res: TranslationState = {
            ...state,
            [lang]: {
                ...(state?.[lang] ?? {}),
                [gameId]: {
                    ...(state?.[lang]?.[gameId]?.data ?? null),
                    status: 'ERROR'
                }
            }
        };
        return res;
    }),

    on(TRANSLATION_ACTIONS.SHOW_TRANSLATION_PRELOAD_MODAL, (state: TranslationState) => {
        let res: TranslationState = {
            ...state,
            showPreloadModal: true
        };
        return res;
    }),
    on(TRANSLATION_ACTIONS.HIDE_TRANSLATION_PRELOAD_MODAL, (state: TranslationState) => {
        let res: TranslationState = {
            ...state,
            showPreloadModal: false
        };
        return res;
    })
);
