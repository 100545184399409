import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupportIssuesPage, UserGuard, ContentPageComponent } from 'army-builder-shared';

import { GameListPage } from './home/game-list.page';
import { HomePage } from './home/home.page';
import { GAME_IDS } from './game-id.token';
import { SettingsPage } from './settings/settings.page';
import { EULAPage } from 'army-builder-shared/src/lib/global/settings/eula.page';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomePage,
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'pages/:pageId',
        component: ContentPageComponent
    },
    {
        path: 'eula',
        component: EULAPage
    },
    {
        path: 'games',
        component: GameListPage,
        canActivate: [UserGuard],
        data: {
            gameId: 'home',
            requiredRoles: ['app_subscriber', 'app_subscriber_annual']
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        data: {
            gameId: 'home'
        }
    },
    {
        path: GAME_IDS.BOLT_ACTION_3E,
        canActivate: [UserGuard],
        loadChildren: () => import('./bolt-action/bolt-action-3e.module').then((m) => m.BoltAction3eModule),
        data: {
            requiredRoles: ['app_subscriber', 'app_subscriber_annual'],
            gameId: GAME_IDS.BOLT_ACTION_3E
        }
    },
    {
        path: 'black-seas',
        canActivate: [UserGuard],
        loadChildren: () => import('./black-seas/black-seas.module').then((m) => m.BlackSeasModule),
        data: {
            requiredRoles: ['app_subscriber', 'app_subscriber_annual'],
            gameId: 'black-seas'
        }
    },
    {
        path: 'blood-red-skies',
        canActivate: [UserGuard],
        loadChildren: () => import('./blood-red-skies/blood-red-skies.module').then((m) => m.BloodRedSkiesModule),
        data: {
            requiredRoles: ['app_subscriber', 'app_subscriber_annual'],
            gameId: 'blood-red-skies'
        }
    },
    {
        path: 'settings',
        children: [
            {
                path: '',
                canActivate: [UserGuard],
                pathMatch: 'full',
                component: SettingsPage,
                data: { trackingId: 'Settings' }
            },
            {
                path: 'debug',
                loadChildren: () => import('army-builder-shared/src/lib/global/debug').then((m) => m.DebugModule),
                data: { trackingId: 'Settings > Debug' }
            }
        ],
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'subscription',
        loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule),
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'support',
        canActivate: [UserGuard],
        component: SupportIssuesPage,
        data: {
            gameId: 'home',
            mode: 'user'
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
