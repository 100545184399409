import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { mapToPaginatedItems, PaginatedItems, RestDataService, Translation } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ArmyBuilderConfig } from '../config';

@Injectable({ providedIn: 'root' })
export class ContentPageService {
    route$: Observable<any> = this.store.select((s: any) => s.router).pipe(distinctUntilChanged());

    pageId$: Observable<any> = this.route$.pipe(
        map((r) => r.state.params.pageId),
        distinctUntilChanged()
    );

    page$ = this.pageId$.pipe(switchMap((pageId) => this.getPage(pageId)));

    constructor(
        private restDataService: RestDataService,
        private store: Store,
        private config: ArmyBuilderConfig
    ) {}

    getPage(path: string) {
        return this.restDataService
            .get(`${this.config.apiBaseUrl}/library/global/pages?pageSize=1&skip=0&query=url:${path}`, {
                cacheKey: `page_${path}`,
                requiresLogin: false
            })
            .pipe(
                mapToPaginatedItems<ContentPage>(),
                map((p: ContentPage[]) => p[0])
            );
    }
}

export interface ContentPage {
    _id: string;
    name: Translation;
    url: string;
    content: Translation;
}
