import { Routes } from '@angular/router';
import { SettingsPage } from './settings.page';
import { UserPage } from './user/user.page';

export const SETTINGS_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: SettingsPage,
        data: { trackingId: 'Settings' }
    },
    {
        path: 'debug',
        loadChildren: () => import('army-builder-shared/src/lib/global/debug').then((m) => m.DebugModule),
        data: { trackingId: 'Settings > Debug' }
    }
];
