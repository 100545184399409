import { Component, Input } from '@angular/core';

import { FormSectionField } from '../form-field';
import { BaseFormControl } from '../base-control';

@Component({ selector: 'abs-form-section-base', template: '' })
export class BaseFormSectionComponent extends BaseFormControl {
    @Input()
    field: FormSectionField;
}
