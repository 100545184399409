import { NgModule } from '@angular/core';

import { ContentPageService } from './page.service';
import { CommonModule } from '@angular/common';
import { ContentPageComponent } from './content.page';
import { PageLayoutModule } from '../page-layout';
import { LanguageModule } from '../language';
import { RouterModule } from '@angular/router';
import { ContentComponent } from './content.component';

@NgModule({
    imports: [CommonModule, RouterModule, PageLayoutModule, LanguageModule],
    exports: [ContentPageComponent, ContentComponent],
    declarations: [ContentPageComponent, ContentComponent],
    providers: [ContentPageService]
})
export class ContentModule {}
