import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'wl-game-list',
    template: `
        <div class="banners">
            <div class="banner">
                <img src="/assets/images/banners/bolt-action.png" alt="Bolt Action" />
            </div>
            <div class="buttons">
                <ion-button [routerLink]="['/bolt-action-3e']">List Builder</ion-button>
                <ion-button [routerLink]="['/bolt-action-3e/library']">Library</ion-button>
            </div>

            <div class="banner">
                <img src="/assets/images/banners/black-seas.png" alt="Black Seas" />
            </div>
            <div class="buttons">
                <ion-button [routerLink]="['/black-seas']">Fleet Builder</ion-button>
                <ion-button [routerLink]="['/black-seas/battle']">Battle Tracker</ion-button>
            </div>

            <div class="banner">
                <img src="/assets/images/banners/blood-red-skies.png" alt="Blood Red Skies" />
            </div>
            <div class="buttons">
                <ion-button [routerLink]="['/blood-red-skies']">Squadron Builder</ion-button>
            </div>
        </div>
    `,
    styles: [
        `
            .banners {
            }

            .banner {
                position: relative;
                border-top: 0;
                height: 120px;
                display: flex;
                align-items: center;
                overflow: hidden;
                border: 3px solid black;
                border-bottom: 0;
            }

            .buttons {
                background: black;
                padding: 0;
                margin: 0 0 10px;
                border: 2px solid black;
            }

            ion-button {
                margin: 1px;
            }

            img {
                display: block;
            }

            h2 {
                background: rgba(0, 0, 0, 0.7);
                padding: 10px 20px;
                color: white;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
            }

            @media (max-width: 599px) {
                .banners {
                    grid-template-columns: auto;
                }

                .banners {
                }

                .banner {
                    position: relative;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                img {
                    display: block;
                }

                h2 {
                    background: rgba(0, 0, 0, 0.7);
                    padding: 10px 20px;
                    color: white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0;
                }
            }
        `
    ]
})
export class GameListPage {
    games = ['bolt-action-3e', 'black-seas', 'blood-red-skies'];
    constructor() {}

    getTranslationKey(gameId: string) {
        return 'GLOBAL.MENU_ITEMS.' + gameId.replace(/\-/g, '_').toUpperCase();
    }
}
