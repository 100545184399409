import { createAction, props } from '@ngrx/store';

export const TRANSLATION_ACTIONS = {
    TRANSLATION_LOADING: createAction('[Global] translationLoading', props<{ gameId: string; lang: string }>()),
    TRANSLATION_LOADED: createAction('[Global] translationLoaded', props<{ gameId: string; lang: string; data: any }>()),
    TRANSLATION_CLEAR_ERROR: createAction('[Global] clearError', props<{ gameId: string; lang: string }>()),
    TRANSLATION_ERROR: createAction('[Global] translationError', props<{ gameId: string; lang: string }>()),
    HIDE_TRANSLATION_PRELOAD_MODAL: createAction('[Global] hideTranslationPreloadModal'),
    SHOW_TRANSLATION_PRELOAD_MODAL: createAction('[Global] showTranslationPreloadModal')
};
