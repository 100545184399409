import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { NativeStorageService, UserService } from 'army-builder-shared';
import { from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserGuard {
    private requiredRoles: string[] = [];

    constructor(
        private router: Router,
        private userService: UserService,
        private nativeStorageService: NativeStorageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        return from(this.nativeStorageService.checkServiceInit()).pipe(
            switchMap(() => this.userService.login$),
            map((l) => {
                if (!userHasAccess(l.user)) {
                    this.router.navigateByUrl('/');
                    return false;
                }

                const requiredRoles = route.data.requiredRoles;
                if (requiredRoles) {
                    // Check if user has any of the required roles
                    const hasRequiredRole = requiredRoles.some((role) => l.user.roles.includes(role));
                    if (!hasRequiredRole) {
                        // User does not have any of the required roles, redirect to unauthorized page

                        this.router.navigateByUrl('/');
                        return false;
                    }
                }

                return true;
            })
        );
    }
}

export function userHasAccess(u) {
    return u?.roles?.includes('app_admin') || u?.roles?.includes('app_user');
}
