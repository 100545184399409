import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { Faction, UnitOption, UnitTemplate } from '../forces/models';
import { HttpRequestConfig, RestDataService } from './restDataService';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { entityDataSelector } from './state/entity-reducer';

@Injectable({ providedIn: 'root' })
export abstract class DataLibrary {
    abstract factions$: Observable<Faction[]>;
    abstract unitTemplates$: Observable<UnitTemplate[]>;
    abstract options$: Observable<UnitOption[]>;
    abstract gameId: string;

    ready$ = this.store.select(entityDataSelector).pipe(
        map((s) => {
            const gameData = s.data[this.gameId];
            if (!gameData) {
                return false;
            }
            const values = Object.values(gameData);
            const ready = !values.some((v) => v.status !== 'LOADED');
            return ready;
        })
    );
    abstract init(opts: HttpRequestConfig): void;

    constructor(
        protected restDataService: RestDataService,
        protected store: Store,
        protected route: ActivatedRoute
    ) {}
}
