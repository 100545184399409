import { createReducer, on, State, Action } from '@ngrx/store';

import { BattleUnit } from '../models';

import {
    nextRound,
    setBattleData,
    resetRound,
    addUnitToBattle,
    setUnitStatus,
    renameUnit,
    removeUnitFromBattle,
    saveBattleUnit,
    setRound,
    resetBattle
} from './actions';

export interface BattleState {
    [key: string]: any;
    round: number;
    units: BattleUnit[];
}

export interface GlobalBattleState {
    [gameId: string]: BattleState;
}

export const initialBattleState: GlobalBattleState = {};

export function battleReducer(state: BattleState | undefined, action: Action) {
    return createReducer(
        initialBattleState,
        on(nextRound, (state: BattleState, { gameId }) => ({
            ...state,
            [gameId]: {
                ...state[gameId],
                round: state[gameId].round + 1
            }
        })),
        on(setRound, (state: BattleState, { gameId, round }) => ({
            ...state,
            [gameId]: {
                ...state[gameId],
                round
            }
        })),
        on(resetRound, (state: BattleState, { gameId }) => ({
            ...state,
            [gameId]: {
                ...initialBattleState[gameId]
            }
        })),
        on(resetBattle, (state: BattleState, { gameId, newState }) => ({
            ...state,
            [gameId]: {
                ...newState
            }
        })),
        on(setBattleData, (_state: BattleState, { newState }) => {
            return {
                ...newState
            };
        }),
        on(addUnitToBattle, (state: BattleState, { gameId, unit }) => {
            const existingUnitIndex = state[gameId].units.findIndex((bu) => bu.unit.id === unit.unit.id);
            let existingUnit: any = {};
            if (existingUnitIndex > -1) {
                existingUnit = state[gameId].units[existingUnitIndex];
            }
            unit = {
                ...unit,
                status: existingUnit.status || unit.status || {},
                modifiers: existingUnit.modifiers || []
            };

            return {
                ...state,
                [gameId]: {
                    ...state[gameId],
                    units: [...state[gameId].units.filter((bu) => bu.unit.id !== unit.unit.id), unit]
                }
            };
        }),
        on(setUnitStatus, (state: BattleState, { gameId, battleUnitId, statusKey, statusValue }) => ({
            ...state,
            [gameId]: {
                ...state[gameId],
                units: [
                    ...state[gameId].units.map((bu) =>
                        bu.id !== battleUnitId
                            ? bu
                            : {
                                  ...bu,
                                  status: {
                                      ...bu.status,
                                      [statusKey]: statusValue
                                  }
                              }
                    )
                ]
            }
        })),
        on(removeUnitFromBattle, (state: BattleState, { gameId, battleUnitId }) => ({
            ...state,
            [gameId]: {
                ...state[gameId],
                units: [...state[gameId].units.filter((bu) => bu.id !== battleUnitId)]
            }
        })),
        on(renameUnit, (state: BattleState, { gameId, battleUnitId, newName }) => ({
            ...state,
            [gameId]: {
                ...state[gameId],
                units: [
                    ...state[gameId].units.map((bu: BattleUnit) => ({
                        ...bu,
                        name: bu.id === battleUnitId ? newName : bu.unit.customName
                    }))
                ]
            }
        })),

        on(saveBattleUnit, (state: BattleState, { gameId, unit }) => ({
            ...state,
            [gameId]: {
                ...state[gameId],
                units: [
                    ...state[gameId].units.map((u: BattleUnit) => {
                        if (u.id === unit.id) {
                            return {
                                ...unit
                            };
                        }
                        return u;
                    })
                ]
            }
        }))
    )(state, action);
}

export interface GameSpecificReducer {
    gameId: string;
    reducer: Function;
    initialBattleState: any;
}
export const CUSTOM_BATTLE_REDUCERS: { [gameId: string]: GameSpecificReducer } = {};
export const registerBattleReducer = (gameSpecificReducer: GameSpecificReducer) => {
    console.log('Registering battle reducer for ' + gameSpecificReducer.gameId);
    CUSTOM_BATTLE_REDUCERS[gameSpecificReducer.gameId] = gameSpecificReducer;
};
export const getCombinedBattleReducers = () => {
    return (state, action: Action) => {
        const intendedGameId = (action as any).gameId;
        let newState = structuredClone(state);

        const gameReducer = CUSTOM_BATTLE_REDUCERS[intendedGameId];

        if (gameReducer && !newState[intendedGameId]) {
            newState[intendedGameId] = gameReducer.initialBattleState;
        }

        newState = battleReducer(newState, action);

        if (gameReducer) {
            newState[intendedGameId] = gameReducer.reducer(newState[intendedGameId], action);
        }

        return newState;
    };
};
