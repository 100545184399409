import { NgModule } from '@angular/core';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as fromRouter from '@ngrx/router-store';

import { CustomSerializer } from '../routing/custom-route-serializer';
import { forceReducer } from '../forces/state/reducer';
import { TranslationState, translationReducer } from './state/translation';

import { entityConfig } from './entity-metadata';
import { changedEntitiesReducer } from './data/reducer';
import { EntityChangeEffects } from './data/effects';
import { loadEntityChangeDataFromCache } from './data';
import { resetAppState } from './data/meta-reducers';
import { EntityLibraryState, GameLibraryState } from './army-builder-entity-store';
import { entityDataReducer } from './state/entity-reducer';

export interface ArmyBuilderState {
    router: fromRouter.RouterReducerState<any>;
    translation: TranslationState;
    entity: {
        [gameId: string]: GameLibraryState;
    };
}

export const selectRouter = createFeatureSelector<ArmyBuilderState, fromRouter.RouterReducerState<any>>('router');

const {
    // selectCurrentRoute, // select the current route
    // selectQueryParams, // select the current route query params
    // selectQueryParam, // factory function to select a query param
    // selectRouteParams, // select the current route params
    // selectRouteParam, // factory function to select a route param
    selectRouteData // select the current route data
    // selectUrl // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const getRouteData = createSelector(selectRouteData, (x) => x);
export const getLibraryData = createFeatureSelector<EntityLibraryState>('entity');

@NgModule({
    imports: [
        StoreModule.forRoot(
            {
                router: routerReducer,
                forces: forceReducer,
                translation: translationReducer,
                entity: entityDataReducer
            },
            { metaReducers: [resetAppState] }
        ),
        EffectsModule.forRoot([]),
        EffectsModule.forFeature([EntityChangeEffects]),
        EntityDataModule.forRoot(entityConfig),
        StoreDevtoolsModule.instrument(),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        StoreModule.forFeature('entityChange', changedEntitiesReducer),
        IonicStorageModule
    ],
    providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
    exports: []
})
export class ArmyBuilderStoreModule {
    constructor(private store: Store<any>) {
        this.store.dispatch(loadEntityChangeDataFromCache());
    }
}
