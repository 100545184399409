import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, firstValueFrom, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    entitySyncComplete,
    startEntitySync,
    SelectSyncStatus,
    entitySyncError,
    removeEntityFromChangeCache,
    EntityChangeSelector
} from '../data';
import { Force } from '../../forces';
import { snapshot } from '../utils';
import { selectForces } from '../../forces/state/selectors';
import { FORCE_ACTIONS } from '../../forces/state/actions';

import { SyncConflictComponent } from './sync-conflict.component';
import { BaseSyncService } from './sync.base';

const mapToForceId = (f) => f.forceId;
const getForceIdsByState = (forces, state) => forces.filter((f) => f.state === state).map(mapToForceId);
export interface UserDataSyncStatus {
    status: 'SYNCING' | 'IDLE' | 'ERROR';
    message?: string;
}

const SYNC_DEBOUNCE = 10000;

const getCacheHash = (cache) => {
    if (!cache) {
        return '';
    }
    return cache.map(({ forceId, state }) => `${forceId}:${state}`).join('|');
};

@Injectable({ providedIn: 'root' })
export class ForceSyncService extends BaseSyncService {
    MIN_LOADING_DELAY = 2000;
    entityType = 'forces';
    syncStatus$ = new BehaviorSubject<UserDataSyncStatus>({ status: 'IDLE' });
    syncStatusSubscription: Subscription;
    firstSyncComplete = false;

    forceCache$: Observable<Force[]> = this.store.select(selectForces).pipe(shareReplay(1));
    entityChangeCache$ = this.store.select(EntityChangeSelector).pipe(
        map((s) => s.forces),
        map((forces) => Object.entries(forces || {}).map((f) => ({ forceId: f[0], state: f[1] }))),
        distinctUntilChanged((prev, current) => getCacheHash(prev) === getCacheHash(current)),
        // tap((x) => console.log('!!! forceSyncService.entityChangeCache$ emitted a new value')),
        shareReplay(1)
    );

    deletedForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Deleted')));
    dirtyForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Dirty')));
    cleanForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Clean')));
    erroredForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Error')));

    init() {
        setTimeout(() => {
            this.deletedForces$.pipe(debounceTime(SYNC_DEBOUNCE)).subscribe((toDelete) => this.uploadForces({ toSync: [], toDelete }));
            this.dirtyForces$
                .pipe(
                    switchMap((forceIds) => this.forceCache$.pipe(map((forces) => forces.filter((f) => forceIds.includes(f.id))))),
                    debounceTime(SYNC_DEBOUNCE)
                )
                .subscribe((toSync) => this.uploadForces({ toSync, toDelete: [] }));
            if (!this.syncStatusSubscription) {
                let firstSyncInProgress = false;
                this.syncStatusSubscription = this.syncStatus$.subscribe((val) => {
                    // console.log(val);
                    if (val.status === 'SYNCING' && !this.firstSyncComplete && !firstSyncInProgress) {
                        firstSyncInProgress = true;
                    } else if (val.status === 'IDLE') {
                        firstSyncInProgress = false;
                        setTimeout(() => {
                            this.firstSyncComplete = true;
                        }, this.MIN_LOADING_DELAY);
                    }
                });
            }
        }, 1000);
    }

    async sync() {
        this.syncStatus$.next({ status: 'SYNCING' });
        const serverTimestamps = await firstValueFrom(this.getServerTimestamps());

        const forceIdsOnServer = Object.keys(serverTimestamps).map((x) => '' + x);
        const forceIdsToDownload = [];
        const forceIdsToUpload = [];
        const localForces = await firstValueFrom(this.getForcesFromState());
        const deletedForces = await firstValueFrom(this.deletedForces$);
        for (const id of forceIdsOnServer) {
            const serverTs = serverTimestamps[id];

            const localForce = localForces.find((f) => f.id === id);
            if (!localForce && !deletedForces.includes(id)) {
                forceIdsToDownload.push(id);
            } else if (localForce && localForce.updatedAt < serverTs) {
                forceIdsToDownload.push(id);
            }
        }

        this.syncStatus$.next({ status: 'SYNCING', message: `${forceIdsToDownload?.length || 0} forces syncing from server` });
        this.syncStatus$.next({ status: 'SYNCING', message: `${forceIdsToUpload?.length || 0} forces syncing from local` });

        if (forceIdsToDownload.length === 0) {
            setTimeout(() => {
                console.log('force sync stopping - no updated forces to download');
                this.refreshSyncStatus();
            }, this.MIN_LOADING_DELAY);
        } else {
            forceIdsToDownload.forEach((id, i, all) => {
                this.syncStatus$.next({ status: 'SYNCING', message: `Adding force ${i + 1} of ${all.length}` });
                this.downloadForce(id);
            });
        }

        if (forceIdsToUpload.length > 0) {
            this.syncStatus$.next({ status: 'SYNCING', message: `Uploading ${forceIdsToUpload.length} forces to server` });
        }

        // Remember - this doesn't actually upload all forces,
        // just the ones that need to be uploaded based on timestamp
        this.uploadForces({
            toSync: localForces,
            toDelete: []
        });
    }

    uploadForces(forces: { toSync: Force[]; toDelete: string[] }, serverTimestamps: { [key: string]: number } = null) {
        forces.toSync.forEach((force) => {
            const ts = serverTimestamps?.[force.id];
            this.syncForce(force, ts);
        });
        forces.toDelete.forEach((forceId) => {
            const url = `${this.config.apiBaseUrl}/userData/forces/${forceId}`;
            const entityChangeCachePayload = { entityType: 'forces', entityId: forceId };
            this.httpClient
                .delete(url, null, {
                    headers: { ...this.config.globalRequestHeaders },
                    withCredentials: true,
                    requiresLogin: true
                })
                .pipe(
                    catchError((_err) => {
                        console.error('entitySyncError', _err);
                        this.store.dispatch(entitySyncError(entityChangeCachePayload));
                        return null;
                    }),
                    filter((x) => !!x)
                )
                .subscribe((res) => {
                    console.log('removing ' + forceId + ' from cache');
                    this.store.dispatch(removeEntityFromChangeCache(entityChangeCachePayload));
                });
        });
    }

    getForcesFromServer(): Observable<Force[]> {
        const url = `${this.config.apiBaseUrl}/userData/forces`;
        const records = this.httpClient
            .get(url, {
                headers: { ...this.config.globalRequestHeaders },
                withCredentials: true,
                requiresLogin: true
            })
            .pipe(
                withLatestFrom(this.deletedForces$),
                map(([data, deletedForces]) =>
                    data
                        .map((r) => r.data)
                        .filter((x) => {
                            // TODO: why is this not working?
                            const deletedForceIds = deletedForces.map((df) => df.id);
                            return !deletedForceIds.includes(x.id);
                        })
                )
            );
        return records;
    }

    private getForcesFromState() {
        return this.store.select(selectForces).pipe(
            map((forces) => {
                return [
                    ...forces.map((f: Force) => {
                        let units = f.units;
                        if (!units && (f as any).data?.units) {
                            // Fixes a data bug caused by the old sync process
                            units = (f as any).data.units;
                        }

                        if (!units && (f as any).changes?.units) {
                            // Fixes a data bug caused by the old sync process
                            units = (f as any).changes.units;
                        }

                        return {
                            ...f,
                            units: units.map((u) => ({
                                ...u,
                                unitTemplate: undefined
                            }))
                        };
                    })
                ].filter((f) => f);
            })
        );
    }

    syncForce(_force: Force, serverTimestamp: number = null) {
        const force = {
            ..._force,
            appVersion: this.config.version,
            units: _force.units.map((unit) => {
                const u = structuredClone(unit);
                delete u.unitTemplate;
                return u;
            })
        };
        snapshot(this.getForceStatus(force), async (forceStatus) => {
            if (forceStatus === 'InProgress') {
                return;
            }

            this.start('forces', force.id);

            const lastSyncTime = force.updatedAt || 0;
            const existsOnServer = lastSyncTime > 0;

            if (!existsOnServer) {
                this.uploadForce(force);
                return;
            }

            if (serverTimestamp === null) {
                serverTimestamp = await firstValueFrom(this.getServerTimestamp(force.id));
            }

            const changesToUpload = forceStatus === 'Dirty';
            const changesToDownload = lastSyncTime < serverTimestamp;

            if (lastSyncTime > serverTimestamp && serverTimestamp !== 0) {
                console.log('Handling sync conflict (timestamp mismatch)', force.id);
                this.handleConflict(force, serverTimestamp);
            } else if (changesToDownload && changesToUpload) {
                console.log('Handling sync conflict (changes on both)', force.id);
                this.handleConflict(force, serverTimestamp);
            } else if (changesToUpload) {
                console.log('Uploading force', force.id);
                this.uploadForce(force);
            } else if (changesToDownload) {
                console.log('Downloading force', force.id);
                this.downloadForce(force.id);
            } else {
                // Everything is up to date
                // Dispatch a message?
                this.complete('forces', force.id);
            }
        });
    }

    private async handleConflict(force: any, serverTimestamp: number) {
        const modal = await this.modalController.create({
            component: SyncConflictComponent,
            componentProps: {
                localTimestamp: force.updatedAt,
                remoteTimestamp: serverTimestamp,
                item: force,
                uploadItem: (f) => this.uploadForce(f),
                downloadItem: (f) => this.downloadForce(f)
            }
        });
        modal.present();
        this.error('forces', force.id);
    }

    protected start(entityType: string, entityId?: string) {
        this.store.dispatch(startEntitySync({ entityType, entityId }));
    }

    protected error(entityType: string, entityId: string) {
        setTimeout(() => {
            console.log('entitySyncError', { entityType, entityId });
            this.store.dispatch(entitySyncError({ entityType, entityId }));
        }, 500);
    }

    protected complete(entityType: string, entityId: string) {
        setTimeout(() => {
            this.store.dispatch(entitySyncComplete({ entityType, entityId }));
            this.refreshSyncStatus();
        }, 500);
    }

    refreshSyncStatus() {
        snapshot(this.dirtyForces$, (dirtyForces) => {
            if (dirtyForces.length === 0) {
                this.syncStatus$.next({ status: 'IDLE', message: null });
            }
        });
    }

    addForceToState(force: Force) {
        snapshot(combineLatest([this.dirtyForces$, this.deletedForces$]), ([_dirtyForces, deletedForces]) => {
            if (deletedForces.includes(force.id)) {
                return;
            }

            const action = FORCE_ACTIONS.UPSERT_FORCE({ force });
            this.store.dispatch(action);
            this.complete('forces', force.id);
        });
    }

    handleSyncError(forceId: string) {
        return catchError((err) => {
            this.error('forces', forceId);
            throw err;
        });
    }

    downloadForce(forceId: string) {
        const headers = this.config.globalRequestHeaders;
        this.httpClient
            .get(this.getSyncUrl(forceId), { headers, withCredentials: true, requiresLogin: true })
            .pipe(this.handleSyncError(forceId))
            .subscribe((serverData: { data: any }) => {
                this.addForceToState(serverData.data);
            });
    }

    uploadForce(force: any) {
        const headers = this.config.globalRequestHeaders;
        let promise: Observable<number>;
        if (force.updatedAt) {
            promise = this.httpClient.put(this.getSyncUrl(force.id), force, {
                headers,
                withCredentials: true,
                requiresLogin: true
            });
        } else {
            promise = this.httpClient.post(this.getSyncUrl(), force, {
                headers,
                withCredentials: true,
                requiresLogin: true
            });
        }

        promise.pipe(this.handleSyncError(force.id)).subscribe((newTimestamp) => {
            const action = FORCE_ACTIONS.UPSERT_FORCE({
                force: {
                    ...force,
                    updatedAt: newTimestamp
                }
            });
            this.store.dispatch(action);
            this.complete('forces', force.id);
        });
    }

    // protected getUpdateAction(force: Force) {
    //     return upsertForce({ force });
    // }

    protected getForceStatus(force: any) {
        return this.store.select(SelectSyncStatus, { entityType: 'forces', id: force.id });
    }

    protected getSettingsStatus() {
        return this.store.select(SelectSyncStatus, { entityType: 'settings' });
    }
}
