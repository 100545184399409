import pkg from '../../package.json';

export const environment = {
    firebase: {
        projectId: 'warlord-235c5',
        appId: '1:19403971558:web:855008724a9885f84c5427',
        storageBucket: 'warlord-235c5.appspot.com',
        apiKey: 'AIzaSyDpfqoKfPIXee9cWqZsr2yIpey4-XEKmpo',
        authDomain: 'warlord-235c5.firebaseapp.com',
        messagingSenderId: '19403971558'
    },
    production: true,
    apiBaseUrl: '/api',
    appVersion: 'v' + pkg.version,
    affiliateId: 40,
    showOfflineLogin: false
};
