import { Component, Input } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { SettingsService, UserService } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'abs-eula-modal',
    template: `
        <abs-modal-layout
            title="End User License Agreement"
            [showCancel]="true"
            cancelBtnText="Decline"
            closeBtnText="Accept"
            (cancel)="decline()"
            (close)="accept()"
            [class]="cssClass"
        >
            <abs-content [path]="'eula'"></abs-content>
        </abs-modal-layout>
    `
})
export class EULAComponent {
    @Input()
    cssClass: string;

    constructor(
        private settingsService: SettingsService,
        private userService: UserService
    ) {}

    decline() {
        this.settingsService.updateValue('global', 'acceptedEULADate', null);
        setTimeout(() => {
            this.userService.logout();
        }, 1000);
    }

    accept() {
        this.settingsService.updateValue('global', 'acceptedEULADate', Date.now());
    }
}
