import { EntityLibraryLoadStatus, EntityLibraryState, GameLibraryState } from '../army-builder-entity-store';

export const updateLibraryStatusReducer = (
    state: EntityLibraryState,
    { gameId, dataType, status }: { gameId: string; dataType: string; status: EntityLibraryLoadStatus }
): EntityLibraryState => {
    return {
        ...state,
        data: {
            ...state.data,
            [gameId]: {
                ...state.data[gameId],
                [dataType]: {
                    ...state.data[gameId][dataType],
                    status
                }
            }
        }
    };
};
