import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';
import { TranslateModule } from '@ngx-translate/core';
import {
    HttpClientWithInFlightCacheModule,
    LanguageModule,
    ModalLayoutModule,
    PipesAndDirectivesModule,
    PurchaseModule,
    RelativeDateModule
} from 'army-builder-shared';

import { PageLayoutModule } from '../global/page-layout';

import { CancelSubscriptionComponent } from './cancel-subscription.component';
import { ManageSubscriptionComponent } from './manage-subscription.component';
import { StripeSubscribeButtonComponent } from './subscribe.component';
import { PaywallComponent } from './paywall.component';
import { SUBSCRIPTION_ROUTES } from './subscription.routes';
import { SubscriptionOptionsComponent } from './subscription-options.component';
import { VoucherDetailsComponent } from './voucher-details.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        IonicModule,
        PageLayoutModule,
        TranslateModule,
        RouterModule.forChild(SUBSCRIPTION_ROUTES),
        PurchaseModule,
        NgLetModule,
        PipesAndDirectivesModule,
        ModalLayoutModule,
        HttpClientWithInFlightCacheModule,
        LanguageModule,
        RelativeDateModule,
        VoucherDetailsComponent
    ],
    exports: [ManageSubscriptionComponent, StripeSubscribeButtonComponent],
    declarations: [
        PaywallComponent,
        ManageSubscriptionComponent,
        StripeSubscribeButtonComponent,
        CancelSubscriptionComponent,
        SubscriptionOptionsComponent
    ],
    providers: []
})
export class SubscriptionModule {}
