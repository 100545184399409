import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomLoader, SelectTranslations, TranslationState } from 'army-builder-shared';
import { BehaviorSubject, debounceTime, map } from 'rxjs';

@Component({
    selector: 'abs-translation-preload-modal',
    template: `
        <ng-container *ngLet="{ data: data$ | async, autoModal: autoModal$ | async } as props">
            <div class="fullPageOverlay" *ngIf="props.data?.showTranslationPreload">
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>Loading Language Data</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <p *ngIf="!props.autoModal">
                            If language data still appears broken after a successful refresh, you may need to close the app and reopen.
                        </p>
                        <abs-translation-preload
                            [gameIds]="gameIds"
                            [language]="language"
                            (retry)="loadTranslationData($event)"
                        ></abs-translation-preload>
                    </ion-card-content>
                </ion-card>
            </div>
        </ng-container>
    `
})
export class TranslationPreloadModalComponent {
    @Input()
    gameIds: string[];

    @Input()
    language: string;

    autoModal$ = this.store.select(SelectTranslations).pipe(map((ts) => !ts.showPreloadModal));

    private _data$ = this.store.select(SelectTranslations).pipe(
        map((ts) => {
            if (!ts) {
                return { showTranslationPreload: false, translationGameIds: {} };
            }
            let loadingKeyCount = 0;

            let languageData = ts[this.language];
            if (!languageData) {
                // Needs to either throw an error or load language data
                return null;
            }

            for (let gameId of this.gameIds) {
                let gameData = languageData[gameId];
                if (!gameData) {
                    // Needs to either throw an error or load language data
                    return null;
                }
                const status = gameData.status;

                if (status === 'LOADING' || status === 'ERROR') {
                    loadingKeyCount++;
                }
            }

            return { showTranslationPreload: ts.showPreloadModal || loadingKeyCount > 0 };
        }),
        debounceTime(100)
    );
    public get data$() {
        return this._data$;
    }
    public set data$(value) {
        this._data$ = value;
    }

    constructor(
        private store: Store,
        private customLoader: CustomLoader
    ) {}

    loadTranslationData({ gameId, language }) {
        this.customLoader.loadData(gameId, language, false);
        // getDataFromApi(gameId, translationKey, { preferCache: false, fallbackToCache: false });
    }
}
