import { CommonModule } from '@angular/common';
import { Component, computed, inject, ViewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, IonInput } from '@ionic/angular';
import { SettingsService, SettingsSyncService, snapshot } from 'army-builder-shared/src/lib';
import { NgLetModule } from 'ng-let';
import { map } from 'rxjs';
import { WarlordUserService } from '../settings/user/user.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'store-account',
    standalone: true,
    imports: [CommonModule, IonicModule, NgLetModule, ReactiveFormsModule],
    template: `
        <div>
            <form [formGroup]="form">
                @if (!form.value.preferredStore) {
                    <p>First, select which store you would like to link with.</p>
                    <div class="stores">
                        <input type="hidden" formControlName="preferredStore" />
                        <img src="/assets/images/store/uk.svg" alt="UK web store" title="UK web store" (click)="selectStore('UK')" />
                        <img src="/assets/images/store/eu.svg" alt="EU web store" title="EU web store" (click)="selectStore('EU')" />
                        <img src="/assets/images/store/us.svg" alt="US web store" title="US web store" (click)="selectStore('US')" />
                    </div>
                } @else {
                    <p *ngIf="!webstoreEmail()">Next, enter the email address of your web store account.</p>
                    <div class="storeDetails">
                        <img
                            src="/assets/images/store/{{ form.value.preferredStore | lowercase }}.svg"
                            alt="{{ form.value.preferredStore }} web store"
                            title="{{ form.value.preferredStore }} web store"
                            (click)="clearPreferredStore()"
                        />
                        <div class="emailInput">
                            @if (webstoreEmail()) {
                                <input type="hidden" formControlName="webstoreEmail" />
                                {{ webstoreEmail() }}
                            } @else {
                                <ion-input type="email" formControlName="webstoreEmail" autocomplete="false"></ion-input>
                                <span class="invalid">
                                    @if (form.touched && form.controls.webstoreEmail.invalid) {
                                        Invalid email address
                                    } @else if (errorCode === 404) {
                                        Web store account not found
                                    } @else if (errorCode === 401) {
                                        Web store account already linked
                                    }
                                </span>
                            }
                        </div>
                        <ion-button *ngIf="!webstoreEmail()" (click)="linkAccount()" [disabled]="validating"
                            >Link <ion-spinner *ngIf="validating"></ion-spinner
                        ></ion-button>
                        <ion-icon *ngIf="webstoreEmail()" class="unlink" name="trash-outline" (click)="unlinkAccount()"></ion-icon>
                    </div>
                }
            </form>
        </div>
    `,

    styles: [
        `
            img {
                height: 30px;
                cursor: pointer;
            }

            .stores {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
            }

            .stores img {
                cursor: pointer;
                border: 3px solid transparent;
            }

            .stores img.selected {
                border: 3px solid var(--ion-color-primary);
            }

            .storeDetails {
                display: flex;
                white-space: nowrap;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1rem;
            }

            .storeDetails > div {
                display: flex;
            }

            .storeDetails img {
                flex: 0 0 auto;
                cursor: pointer;
            }

            .emailInput {
                flex: 1 1 auto;
            }

            .emailInput [disabled] {
                border: 0;
            }

            .unlink {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }

            .emailInput .invalid {
                color: red;
                font-size: 0.8em;
                position: absolute;
            }
        `
    ]
})
export class StoreAccountComponent {
    storeKeys = ['UK', 'US', 'EU'];
    settingsService = inject(SettingsService);
    userService = inject(WarlordUserService);
    settingsSyncService = inject(SettingsSyncService);

    user = toSignal(this.settingsService.login$.pipe(map((l) => l.user)));
    settings = toSignal(this.settingsService.settings$);

    preferredStore = computed(() => this.settings().global?.preferredStore);
    webstoreEmail = computed(() => this.settings().global?.webstoreEmail);

    validating = false;
    accountIsValid: boolean = null;
    errorCode = null;

    fb = inject(FormBuilder);
    form = this.fb.group({
        preferredStore: [this.preferredStore(), [Validators.required]],
        webstoreEmail: [this.webstoreEmail(), [Validators.required, Validators.pattern(/(.+)@(.+){2,}\.(.+){2,}/)]]
    });

    @ViewChild('email') email: IonInput;

    selectStore(store: string) {
        this.settingsService.updateValue('global', 'preferredStore', store);
        this.form.controls.preferredStore.setValue(store);
    }

    async linkAccount() {
        const email = this.form.value.webstoreEmail;
        const store = this.form.value.preferredStore;

        this.validating = true;
        let customerDetails = await this.userService.getWebstoreCustomerDetails({ email, store });
        const webstoreCustomerId = customerDetails?.customerId;
        const voucherCode = customerDetails?.voucherCode;
        this.errorCode = customerDetails?.errorCode;

        this.accountIsValid = !!webstoreCustomerId;

        if (this.accountIsValid && this.form.valid) {
            this.settingsService.updateValue('global', 'webstoreCustomerId', webstoreCustomerId);
            this.settingsService.updateValue('global', 'webstoreEmail', email);
            this.settingsService.updateValue('global', 'preferredStore', store);
            const settings = this.settings();
            this.settingsSyncService.uploadSettings(settings);
        }

        this.settingsService.updateUserMetadataValue('voucherCode', voucherCode);

        this.validating = false;
    }

    unlinkAccount() {
        this.form.controls.preferredStore.reset('');
        this.settingsService.updateValue('global', 'webstoreEmail', '');
        this.settingsService.updateValue('global', 'preferredStore', '');
    }

    clearPreferredStore() {
        this.form.controls.preferredStore.reset('');
    }
}
