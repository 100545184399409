import { Injectable } from '@angular/core';
import { catchError, defaultIfEmpty, filter, map, shareReplay, switchMap, take, tap, timeout } from 'rxjs/operators';
import { combineLatest, firstValueFrom, from, Observable, of, throwError } from 'rxjs';

import { NativeStorageService } from '../data/native-storage.service';
import { SettingsService } from '../settings';
import { ArmyBuilderConfig } from '../config';
import { HttpClientRequestOptions, HttpClientWithInFlightCache } from '../httpClient';

const CACHE_TIMEOUT = 1000 * 60 * 60 * 24;

export interface HttpRequestConfig {
    cacheKey?: string;
    requiresLogin?: boolean;
    preferCache?: boolean;
    useCache?: boolean;
    fallbackToCache?: boolean;
    mergeWithCache?: boolean;
}
@Injectable({ providedIn: 'root' })
export class RestDataService {
    serverCacheTime$: Observable<Date>;
    localCacheTime$: Observable<Date>;
    cacheUrl: string;
    constructor(
        private httpClient: HttpClientWithInFlightCache,
        private storage: NativeStorageService,
        private settingsService: SettingsService,
        private config: ArmyBuilderConfig
    ) {
        this.cacheUrl = this.config.apiBaseUrl + '/library/cacheTime';
        this.serverCacheTime$ = this.httpClient.get(this.cacheUrl).pipe(
            shareReplay(1),
            catchError(() => of(new Date(0)))
        );
    }

    get(url: string, config: HttpRequestConfig) {
        const combinedConfig: HttpRequestConfig = {
            requiresLogin: false,
            preferCache: true,
            useCache: true,
            fallbackToCache: true,
            mergeWithCache: false,
            ...config
        };
        const opts: HttpClientRequestOptions = {
            headers: {}
        };

        let { cacheKey, requiresLogin, preferCache, useCache, mergeWithCache, fallbackToCache } = combinedConfig;

        if (requiresLogin) {
            opts.withCredentials = true;
            opts.requiresLogin = true;
        }

        return this.settingsService.loggedIn$.pipe(
            filter((loggedIn) => {
                return !requiresLogin || loggedIn;
            }),
            switchMap(() => {
                return from(
                    this.storage.getItem(cacheKey, undefined).then((cached) => {
                        const urlParams = new URLSearchParams(location.search);
                        if (urlParams?.get('clearCache')) {
                            preferCache = false;
                        }

                        console.log('Fetching data from server: ', cacheKey, url);
                        let cachedItems = [];

                        if (mergeWithCache) {
                            cachedItems = cached?.data?.items || [];
                            // Update URL to only fetch entities since a given time
                            if (url.indexOf('?') > -1) {
                                url += '&';
                            } else {
                                url += '?';
                            }

                            let localCacheTime = Math.max(0, ...(cached?.data?.items || []).map((x) => new Date(x?.lastUpdated || 0)));
                            url += `since=${new Date(localCacheTime || 0).getTime()}`;
                        }

                        return firstValueFrom(
                            this.httpClient.get(url, opts).pipe(
                                timeout(30000), // TODO: Make this config
                                map((data) => {
                                    let res = data;

                                    if (mergeWithCache) {
                                        let mergedItems = [...cachedItems];
                                        console.log(`Merging ${data.items.length} items for ${cacheKey}`);
                                        for (let item of data.items) {
                                            let existingIndex = mergedItems.findIndex((x) => x._id === item._id);
                                            if (existingIndex > -1) {
                                                mergedItems[existingIndex] = item;
                                            } else {
                                                mergedItems.push(item);
                                            }
                                        }

                                        res = {
                                            ...res,
                                            itemCount: mergedItems.length,
                                            items: mergedItems
                                        };
                                    }

                                    if (useCache) {
                                        console.log(`Caching ${data.length} items for ${cacheKey}`);
                                        this.storage.setItem(cacheKey, { timestamp: Date.now(), data: res });
                                    }

                                    return res;
                                }),
                                catchError((err) => {
                                    if (err.status === 401) {
                                        console.error('User is no longer logged in');
                                        return;
                                    }
                                    console.groupCollapsed(
                                        'RestDataService: Error retrieving ' + cacheKey + ', falling back to cache.',
                                        err
                                    );
                                    console.error('RestDataService: Error retrieving ' + cacheKey + ', falling back to cache.', err);
                                    console.trace('RestDataService: Error stack trace');
                                    console.groupEnd();

                                    if (fallbackToCache && cached.data) {
                                        return of(cached.data);
                                    }
                                    return throwError(() => err);
                                })
                            )
                        );
                    })
                );
            }),
            take(1)
        );
    }
}
