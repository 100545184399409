import { createAction, createSelector, MemoizedSelector, props } from '@ngrx/store';
import { entityDataSelector } from './state/entity-reducer';

export type EntityLibraryLoadStatus = 'NOT_STARTED' | 'LOADING' | 'LOADED' | 'ERROR';

export interface GameEntityLibraryState<T> {
    status: EntityLibraryLoadStatus;
    data: T[];
}

export interface GameLibraryState {
    [enityType: string]: GameEntityLibraryState<any>;
}

export interface EntityLibraryState {
    showPreloadModal?: string;
    data: { [gameId: string]: GameLibraryState };
}

export const initialEntityLibraryGameState: EntityLibraryState = {
    showPreloadModal: null,
    data: {}
};

export const ENTITY_LIBRARY_ACTIONS = {
    INIT_GAME_LIBRARY: createAction('[EntityLibrary] initGameLibrary', props<{ gameId: string; initialState: GameLibraryState }>()),
    UPDATE_LIBRARY_STATUS: createAction(
        '[EntityLibrary] loadingData',
        props<{ gameId: string; dataType: string; status: EntityLibraryLoadStatus }>()
    ),
    LOAD_ENTITY_DATA_AVAILABLE: createAction(
        '[EntityLibrary] loadEntityDataAvailable',
        props<{ gameId: string; dataType: string; data: any[] }>()
    ),
    LOAD_ENTITY_DATA_COMPLETE: createAction(
        '[EntityLibrary] loadEntityDataComplete',
        props<{ gameId: string; dataType: string; data: any[] }>()
    ),
    SHOW_ENTITY_PRELOAD_MODAL: createAction('[EntityLibrary] showEntityPreloadModal', props<{ gameId: string }>()),
    HIDE_ENTITY_PRELOAD_MODAL: createAction('[EntityLibrary] hideEntityPreloadModal')
};
