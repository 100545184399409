import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { UserService } from '../../global';
import { ToastService } from '../../global/toast/toast';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'abs-analytics',
    template: `
        <abs-page-layout title="Analytics">
            <canvas #barCanvas style="position: relative; height:400px; width:100%"></canvas>
            <div class="buttons">
                <ion-button (click)="archiveData()">Archive</ion-button>
            </div>
            <div class="buttons" [formGroup]="form">
                <ion-input formControlName="period" type="number"></ion-input>
                <ion-radio-group formControlName="unit" value="days">
                    <ion-radio value="day">Days</ion-radio>
                    <ion-radio value="week">Weeks</ion-radio>
                    <ion-radio value="month">Months</ion-radio>
                </ion-radio-group>
                <!-- <ion-button [fill]="period === 24 ? 'outline' : 'solid'" (click)="regenerateBarchart('hours', 24)">1 day</ion-button>
                <ion-button [fill]="period === 24 * 30 ? 'outline' : 'solid'" (click)="regenerateBarchart('day', 30)">30 day</ion-button>
                <ion-button [fill]="period === 24 * 365 ? 'outline' : 'solid'" (click)="regenerateBarchart('day', 365)">365 day</ion-button> -->
            </div>
        </abs-page-layout>
    `
})
export class AnalyticsPage implements OnDestroy {
    @ViewChild('barCanvas') private barCanvas: ElementRef;

    protected form: FormGroup;
    constructor(
        private userService: UserService,
        private toast: ToastService,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            period: this.formBuilder.control(52),
            unit: this.formBuilder.control('week')
        });

        this.regenerateBarchart();
        this.form.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            this.regenerateBarchart();
        });
    }

    barChart: any;
    timer: any;

    async archiveData() {
        this.userService.archiveStats().then((res) => {
            this.toast.showToast('Success', 'Archived analytics data', { color: 'success', duration: 3000 });
        });
    }

    async regenerateBarchart() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => this.updateData(), 60000);
        this.updateData();
    }

    async updateData() {
        let stats = await this.userService.getStats(this.form.value.unit, this.form.value.period);
        if (this.barChart) {
            this.barChart.data.labels = stats.labels;
            // this.barChart.data.datasets[0].data = stats.eventCount;
            this.barChart.data.datasets[0].data = stats.values;
            this.barChart.update();
        } else {
            this.createChart(stats);
        }
    }

    createChart(stats) {
        if (!stats) {
            console.error('No stats');
            return;
        }
        let self = this;
        Chart.register(...registerables);
        this.barChart = new Chart(this.barCanvas.nativeElement, {
            type: 'bar',
            data: {
                labels: stats.labels,
                datasets: [
                    // {
                    //     type: 'bar',
                    //     label: '# of Events',
                    //     data: stats.eventCount,
                    //     borderWidth: 0,
                    //     backgroundColor: '#C00',
                    //     yAxisID: 'eventAxis'
                    // },
                    {
                        type: 'bar',
                        label: '# of Active Users',
                        data: stats.values,
                        borderWidth: 0,
                        backgroundColor: '#006',
                        yAxisID: 'userAxis'
                    }
                ]
            },
            options: {
                scales: {
                    // eventAxis: {
                    //     position: 'right',
                    //     suggestedMax: 25
                    // },
                    userAxis: {
                        position: 'left',
                        suggestedMax: 10
                    },
                    x: {
                        ticks: {
                            callback: function (value: number) {
                                return this.getLabelForValue(value);
                            }
                        }
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
