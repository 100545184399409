import { Purchases } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { PurchasesPackage } from '@awesome-cordova-plugins/purchases/ngx';

import { PurchasingService } from './purchasing.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PurchasingServiceCapacitor extends PurchasingService {
    async configurePlatform(userId: string) {
        let apiKey: string;

        if (Capacitor.getPlatform() === 'ios') {
            apiKey = this.config.purchaseKeys.apple;
        } else if (Capacitor.getPlatform() === 'android') {
            apiKey = this.config.purchaseKeys.google;
        }

        return await Purchases.configure({ apiKey, appUserID: userId });
    }

    async loadProductsForPlatform() {
        return new Promise<PurchasesPackage[]>((resolve, reject) => {
            setTimeout(() => {
                this.purchases.getOfferings().then(
                    (offerings) => {
                        this.debug.log('configurePurchasing: ', { offerings });
                        const packages = offerings?.current?.availablePackages;
                        this.debug.log('configurePurchasing: ', { packages });
                        resolve(packages);
                    },
                    (error) => {
                        this.debug.log('Error getting offerings: ', error);
                        console.error('Error getting offerings: ', error);

                        reject(null);
                    }
                );
            }, 1000);
        });
    }

    // private getProductFromRCProduct(pkg: PurchasesPackage, productList: Product[]): Product {
    //     return {
    //         ...productList.find((p) => p.key === pkg.identifier),
    //         price: pkg.product.priceString,
    //         package: pkg
    //     };
    // }

    onDeviceReady = async () => {};
}
