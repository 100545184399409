import { Component } from '@angular/core';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { DebugService } from './debug.service';
import { ToastService } from '../toast/toast';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'abs-debug',
    template: `
        <h2>{{ 'GLOBAL.SETTINGS.DEBUG.TITLE' | translate }}</h2>
        <p [innerHTML]="'GLOBAL.SETTINGS.DEBUG.DESCRIPTION' | translate"></p>
        <div class="buttons">
            <ion-button (click)="copyToClipboard(log.value)">{{ 'GLOBAL.SETTINGS.DEBUG.COPY_LOG' | translate }}</ion-button>
        </div>
        <div class="content">
            <textarea #log readonly="true">{{ (debugService.log$ | async)?.join('\\n') }}</textarea>
        </div>
    `,
    styles: [
        `
            .content {
            }
            textarea {
                width: 100%;
                height: 400px;
            }
        `
    ]
})
export class DebugComponent {
    constructor(
        public debugService: DebugService,
        private toast: ToastService,
        private translateService: TranslateService
    ) {}

    copyToClipboard(log: string) {
        navigator.clipboard
            .writeText(log)
            .then(() => {
                this.toast.showToast('', this.translateService.instant('GLOBAL.SETTINGS.DEBUG.COPY_SUCCESS'), { color: 'success' });
            })
            .catch((err) => {
                this.toast.showToast('', this.translateService.instant('GLOBAL.SETTINGS.DEBUG.COPY_ERROR'), { color: 'danger' });
            });
    }
}
