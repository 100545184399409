import { AfterViewInit, Component, ComponentFactoryResolver, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CustomBehaviourHostDirective, PaginatedItems, snapshot } from '../../../../global';
import { AdminEntityService } from '../../service/entity.service';
import { EntityFormConfig } from '../../service/entity-form-config';

import { BaseEntityRowComponent } from './base-entity-row.component';

@Component({
    selector: 'wla-entity-list',
    template: `
        <abs-page-layout
            *ngIf="entityTypeMetaData$ | async as entityTypeMetaData"
            [title]="entityTypeMetaData.title"
            [subTitle]="gameId$ | async"
        >
            <abs-entity-list-filters [gameId]="gameId$ | async" [entityType]="entityType$ | async"></abs-entity-list-filters>
            <div class="content">
                <ion-button (click)="newEntity()"> New {{ entityTypeMetaData.entityName }} </ion-button>
                <ion-button (click)="saveAll()"> Save All </ion-button>
            </div>
            <ng-container *ngIf="entities$ | async as entities">
                <abs-pager [items]="entities"></abs-pager>
                <ng-template #defaultRowTemplate let-item="item">
                    <ion-label>
                        <h2>{{ item.name[lang] }}</h2>
                        <p>{{ item.description?.[lang] }}</p>
                    </ion-label>
                </ng-template>
                <ng-template customBehaviourHost></ng-template>
                <ion-list *ngIf="rowTemplate$ | async as rowTemplate">
                    <ion-item *ngFor="let e of entities.items" [routerLink]="currentUrl + '/' + e._id">
                        <ng-template [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{ item: e }"></ng-template>
                    </ion-item>
                </ion-list>
                <abs-pager [items]="entities"></abs-pager>
            </ng-container>
        </abs-page-layout>
    `,
    styles: [
        `
            abs-entity-list-filters {
                display: block;
                margin: 0 0 10px;
            }
        `
    ]
})
export class EntityListComponent implements AfterViewInit, OnDestroy {
    currentUrl = this.router.url.split('?')[0]; // Hacky fix to bug where links don't work after paging
    lang = 'en';
    filterValue$ = new BehaviorSubject('');
    gameId$ = this.route.params.pipe(map((p) => p.gameId));
    entityType$ = this.route.params.pipe(map((p) => p.entityType));
    entityTypeMetaData$ = this.route.params.pipe(map((p) => this.entityFormConfig.entityTypeMetaData[p.entityType]));
    entityRowTemplate$ = this.route.params.pipe(map((p) => this.entityFormConfig.entityRowTemplates?.[p.gameId]?.[p.entityType]));
    formConfig$ = combineLatest([this.gameId$, this.entityType$]).pipe(
        switchMap(([gameId, entityType]) => this.entityFormConfig.forms[gameId][entityType])
    );
    entities$ = combineLatest([this.gameId$, this.entityType$, this.route.queryParams]).pipe(
        filter(([gameId, entityType]) => !!gameId && !!entityType),
        switchMap(([gameId, entityType, params]) =>
            this.entityService.getEntities(gameId, entityType, params.skip || 0, params.query, params.sort)
        )
    );

    @ViewChild(CustomBehaviourHostDirective) customBehaviours: CustomBehaviourHostDirective;
    @ViewChild('defaultRowTemplate') defaultRowTemplate: TemplateRef<any>;

    rowTemplate$: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
    rowTemplateLoaded = false;
    rowSub: Subscription;

    constructor(
        private entityService: AdminEntityService,
        public route: ActivatedRoute,
        public router: Router,
        private entityFormConfig: EntityFormConfig,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngAfterViewInit() {
        this.rowTemplate$.next(this.defaultRowTemplate);
        this.entities$.subscribe(() => {
            this.loadCustomRow();
        });
    }

    newEntity() {
        snapshot(
            combineLatest([this.gameId$, this.entityType$, this.entityTypeMetaData$, this.formConfig$]),
            ([gameId, entityType, entityTypeMetaData, formConfig]) => {
                // TODO: A more flexible way to provide a default value
                const entity = {
                    ...(formConfig.defaultValue ?? {}),
                    name: {
                        en: `(New ${entityTypeMetaData.entityName})`
                    },
                    description: {
                        en: ''
                    },
                    gameId
                };
                this.entityService
                    .saveEntity(entity, entity.gameId, entityType)
                    .toPromise()
                    .then((res) => {
                        console.log(`Created new entity: ${res[0]}`);
                        this.router.navigateByUrl(`${this.currentUrl}/${res[0]}`);
                    });
            }
        );
    }

    loadCustomRow() {
        if (this.rowTemplateLoaded) {
            return;
        }
        setTimeout(() => {
            this.rowTemplateLoaded = true;
            const gameId = this.route.snapshot.params.gameId;
            const entityType = this.route.snapshot.params.entityType;
            const component = this.entityFormConfig.entityRowTemplates?.[gameId]?.[entityType];

            if (!component || !this.customBehaviours) {
                this.rowTemplate$.next(this.defaultRowTemplate);
                return;
            }

            const componentFactory = this.componentFactoryResolver.resolveComponentFactory<BaseEntityRowComponent>(component);

            const viewContainerRef = this.customBehaviours.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);

            this.rowSub = componentRef.instance.rowTemplate$.subscribe((t) => {
                this.rowTemplate$.next(t);
            });
        }, 100);
    }

    saveAll() {
        if (confirm('Are you sure?')) {
            snapshot(combineLatest([this.gameId$, this.entityType$]), ([gameId, entityType]) => {
                this.entityService
                    .getEntities(gameId, entityType, 0, '', '', 2000)
                    .pipe(
                        switchMap((entities: PaginatedItems) => {
                            if (entities.itemCount !== entities.items.length) {
                                const msg = `Entity count mismatch: itemCount is ${entities.itemCount} but ${entities.items.length} items received`;
                                console.error(msg);
                                throw new Error(msg);
                            }
                            return this.entityService.saveMany(entities.items, gameId, entityType);
                        })
                    )
                    .subscribe((res) => {
                        console.log({ res });
                    });
            });
        }
    }

    ngOnDestroy() {
        if (this.rowSub) {
            this.rowSub.unsubscribe();
        }
    }
}
