import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Capacitor } from '@capacitor/core';
import { AppInjector } from 'army-builder-shared';

import { LanguageModule } from '../language';

import { PurchaseRoutingModule } from './purchase.routing';
import { PurchaseListComponent } from './purchase-list/component';
import { PurchasingService } from './service/purchasing.service';
import { purchaseReducer } from './service/state';
import { PurchasingServiceCapacitor } from './service/purchasing.service.capacitor';
import { PurchasingServiceWeb } from './service/purchasing.service.web';
import { PurchaseHandlerComponent } from './purchase-handler.page';

@NgModule({
    declarations: [PurchaseListComponent, PurchaseHandlerComponent],
    imports: [
        CommonModule,
        IonicModule,
        PurchaseRoutingModule,
        StoreModule.forFeature('purchase', purchaseReducer),
        TranslateModule,
        LanguageModule
    ],
    providers: [],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PurchaseModule {
    constructor(
        private svc: PurchasingService,
        private platform: Platform
    ) {
        // this.platform.ready().then(() => {
        //     setTimeout(() => {
        //         this.svc.restorePurchases();
        //     }, 1000);
        // });
    }

    static forRoot(): ModuleWithProviders<PurchaseModule> {
        return {
            ngModule: PurchaseModule,
            providers: [
                PurchasingServiceCapacitor,
                PurchasingServiceWeb,
                {
                    provide: PurchasingService,
                    useFactory: () => {
                        const platform = Capacitor.getPlatform();
                        if (platform === 'ios' || platform === 'android') {
                            return AppInjector.get(PurchasingServiceCapacitor);
                        } else {
                            return AppInjector.get(PurchasingServiceWeb);
                        }
                    }
                }
            ]
        };
    }
}
