import { Routes } from '@angular/router';

import { PaywallComponent } from './paywall.component';
import { ManageSubscriptionComponent } from './manage-subscription.component';
import { PurchaseHandlerComponent } from 'army-builder-shared';

export const SUBSCRIPTION_ROUTES: Routes = [
    {
        path: 'manage',
        component: ManageSubscriptionComponent
    },
    {
        path: 'subscribe',
        component: PaywallComponent
    },
    {
        path: 'handle-purchase',
        component: PurchaseHandlerComponent,
        data: {
            requiredRoles: ['app_subscriber', 'app_subscriber_annual']
        }
    }
];
