import { firstValueFrom } from 'rxjs';

import { PurchasingService } from './purchasing.service';
import { AddPurchase } from './state';
import { Injectable } from '@angular/core';

@Injectable()
export class PurchasingServiceWeb extends PurchasingService {
    async configurePlatform(userId: string) {
        // No implementation needed as in-app purchases on the web are done via Stripe
    }

    async purchase(productId) {
        console.log('Purchasing: ' + productId);
        // if (!this.platform.is('cordova')) {

        const products = await firstValueFrom(this.products$);
        if (this.config.enableStripePurchases) {
            const product = products.find((p) => p.key === productId);
            const priceId = this.config.environment === 'staging' ? product.stripeKeyStaging : product.stripeKeyProd;

            return firstValueFrom(
                this.http.post(
                    this.config.apiBaseUrl + '/purchase/createCheckoutSession/',
                    {
                        priceId
                    },
                    {
                        headers: this.config.globalRequestHeaders,
                        withCredentials: true
                    }
                )
            ).then((res) => {
                console.log(res);
                return true;
                // TODO: does this need a check of some sort?
                // this.addPurchase(productId);
            });
        } else {
            console.log('Purchases not supported on this platform.');
            this.store.dispatch(AddPurchase({ productId }));
            return false;
        }

        // }
        // this.store.dispatch(SetProductsStatus({ productId, status: 'loading' }));
        // snapshot(this.products$, (products) => {
        //     const pkg = products.find((p) => p.key === productId).package;
        //     this.debug.log('Ordering From Store: ' + productId, JSON.stringify(pkg));
        //     this.purchases
        //         .purchasePackage(pkg)
        //         .then(({ productIdentifier, customerInfo }) => {
        //             this.debug.log(JSON.stringify({ productId, pkg, productIdentifier, customerInfo }));
        //             if (typeof customerInfo.entitlements.active[productId] !== 'undefined') {
        //                 this.addPurchase(productId);
        //                 this.debug.log(`${productId} added`);
        //             }
        //         })
        //         .catch(({ error, userCancelled }) => {
        //             // Error making purchase
        //             console.log(error, userCancelled);
        //             this.debug.log('Error during purchase: ' + JSON.stringify({ error, userCancelled }));
        //         });
        // });
    }

    async loadProductsForPlatform() {
        return [];
    }
}
