import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of, switchMap } from 'rxjs';
import { ENTITY_LIBRARY_ACTIONS } from '../army-builder-entity-store';

@Injectable()
export class DefaultEntityLibraryEffects {
    interceptAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ENTITY_LIBRARY_ACTIONS.LOAD_ENTITY_DATA_AVAILABLE),
            switchMap((action: any) => {
                const { gameId, dataType, data } = action;

                return of(
                    ENTITY_LIBRARY_ACTIONS.LOAD_ENTITY_DATA_COMPLETE({
                        gameId,
                        dataType,
                        data
                    })
                );
            })
        )
    );

    constructor(private actions$: Actions) {}
}
