import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalise'
})
export class CapitalisePipe implements PipeTransform {
    transform(value: string): string {
        return capitalise(value);
    }
}

export const capitalise = (value: string, preserveSpaces = false) => {
    if (!value || !value.split) {
        return value;
    }
    return value
        .split(' ')
        .map((val) => val[0].toUpperCase() + val.slice(1).toLowerCase())
        .join(preserveSpaces ? ' ' : '');
};
