import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { markEntityAsDeleted, markEntityAsDirty } from '../../global';

import { FORCE_ACTIONS } from './actions';

@Injectable()
export class ForceEffects {
    constructor(
        private actions$: Actions,
        private store: Store<any>
    ) {
        this.actions$
            .pipe(ofType(FORCE_ACTIONS.UPDATE_FORCE, FORCE_ACTIONS.ADD_FORCE, FORCE_ACTIONS.UPSERT_FORCE))
            .subscribe((changeData) => {
                this.store.dispatch(markEntityAsDirty({ entityType: 'forces', entityId: changeData.force.id }));
            });

        this.actions$
            .pipe(
                ofType(
                    FORCE_ACTIONS.ADD_POINTS_ADJUSTMENT,
                    FORCE_ACTIONS.REMOVE_POINTS_ADJUSTMENT,
                    FORCE_ACTIONS.DELETE_PLATOON,
                    FORCE_ACTIONS.DUPLICATE_PLATOON,
                    FORCE_ACTIONS.ADD_PLATOON,
                    FORCE_ACTIONS.ADD_UNIT_OPTION,
                    FORCE_ACTIONS.REMOVE_UNIT_OPTION
                )
            )
            .subscribe((changeData) => {
                this.store.dispatch(markEntityAsDirty({ entityType: 'forces', entityId: changeData.forceId }));
            });

        this.actions$.pipe(ofType(FORCE_ACTIONS.DELETE_FORCE)).subscribe((changeData) => {
            this.store.dispatch(markEntityAsDeleted({ entityType: 'forces', entityId: changeData.id, updatedAt: changeData.updatedAt }));
        });
    }
}
