import { Component, Input, OnInit } from '@angular/core';

import { ContentPage, ContentPageService } from './page.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'abs-content',
    template: ` <div class="content" *ngIf="page$ | async as page" [innerHTML]="page.content | language | async"></div> `,
    styles: [``]
})
export class ContentComponent {
    @Input({ required: true })
    path: string;

    page$: Observable<ContentPage>;
    constructor(public pageService: ContentPageService) {}

    ngOnInit() {
        this.page$ = this.pageService.getPage(this.path);
    }
}
